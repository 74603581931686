import React, { useRef } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import nudgePng from '../../images/health-tracker/nudge.png';
import nudgeNext from '../../images/health-tracker/double-right.png';
import nudgePrevious from '../../images/health-tracker/double-left.png';

import { Nudge, HealthScoreNudgesProps } from './HealthTrackerConstants';

const backupNudges: Nudge[] = [
    {
        name: "Calories",
        message: "Tip: cleaning your house burns calories - put the music up and let the burn happen!",
        nudgeType: "ScoreUp",
    },
    {
        name: "Steps (Average)",
        message: "If you take transport, you can increase your steps by getting off a little earlier and walking the last stretch.",
        nudgeType: "ScoreUp",
    }
];

const HealthScoreNudges: React.FC<HealthScoreNudgesProps> = ({ nudges }) => {
    const swiperRef = useRef<SwiperCore | null>(null);

    // Use the provided nudges or fall back to backup nudges
    const effectiveNudges = nudges.length > 0 ? nudges : backupNudges;

    const sortedNudges = effectiveNudges
        .slice()
        .sort((a: Nudge, b: Nudge) => b.message.length - a.message.length);

    const handlePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.slidePrev();
        }
    };

    const handleNext = () => {
        if (swiperRef.current) {
            swiperRef.current.slideNext();
        }
    };

    return (
        <div className="md:py-md h-full">
            <div className="bg-lime-fg-light p-md h-full flex flex-col justify-center items-center relative">
                <h2 className="text-sherpa-blue text-h2 mb-4 w-full">
                    <img src={nudgePng} alt="Nudge" className="inline mr-2" /> Nudges
                </h2>
                <Swiper
                    modules={[Autoplay, Pagination]}
                    spaceBetween={50}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    onSwiper={(swiper: any) => {
                        swiperRef.current = swiper;
                    }}
                    className="w-full"
                >
                    {sortedNudges.map((nudge: Nudge, index: number) => (
                        <SwiperSlide key={index}>
                            <div className="nudge-item p-4 bg-white rounded">
                                {nudge.name && <h4 className="text-lg font-semibold mb-2">{nudge.name}</h4>}
                                <p>{nudge.message}</p>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                {
                    (nudges.length > 1) && (
                        <div id="healthTrackerNudgeNavigation">
                            <span onClick={handlePrev}>
                                <img src={nudgePrevious} alt="Previous"/>
                            </span>
                                    <span onClick={handleNext}>
                                <img src={nudgeNext} alt="Next"/>
                            </span>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default HealthScoreNudges;
