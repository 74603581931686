import React, {useState, useEffect} from "react";
import {composeUrl} from "../../utils/api";
import Configuration from "../../models/Configuration";
import { openReframeService, ReframeServiceId } from "./Utilities/CnUtilities";
import { useAuth } from "react-oidc-context";

function ReframeAccessSupportForMe() {
    const auth = useAuth();
    const [config, setConfig] = useState<Configuration | null>(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isConsentGiven, setIsConsentGiven] = useState(false);

    useEffect(() => {
        async function fetchConfig() {
            const url = composeUrl('configuration');
            const response = await fetch(url);
            const config = await response.json();
            setConfig(config);
            setLoading(false);
        }

        fetchConfig();
    }, []);

    const handleReframeAccess = () => openReframeService(ReframeServiceId.AccessForMe, auth, config);

    const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsConsentGiven(event.target.checked);
    };

    const buttonClassName = isConsentGiven
        ? "w-fit block self-center px-xl py-sm font-bold text-primary-text bg-button-background-blue border-none rounded-md font-sans-co whitespace-nowrap"
        : "w-fit block self-center px-xl py-sm font-bold text-primary-text bg-button-background-grey border-none rounded-md font-sans-co whitespace-nowrap opacity-50 cursor-not-allowed";

    return (
        <div>
            <div className="m-auto text-h3 font-normal">
                <div>
                    <p className="mb-lg">
                        Access specialists and a vast array of resources dedicated to your cancer journey using
                        our partner Reframe. From emotional support to practical advice, our team is here,
                        ensuring you're equipped and empowered every step of the way.
                    </p>
                </div>
                <div className="mb-lg">
                    <p>
                        <input type="checkbox" checked={isConsentGiven} onChange={handleConsentChange} className="me-3"/>
                        I consent to Lime sharing my personal data with Reframe where this is necessary for providing the Cancer Support Service.
                        Please see our <a target="_blank"
                                          href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/MemberPrivacyPolicy.pdf">Privacy
                        Policy</a> for more info.
                    </p>
                </div>
                <div className="mb-lg">
                    <button className={buttonClassName} onClick={handleReframeAccess} disabled={!isConsentGiven}>
                        Navigate to Reframe
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ReframeAccessSupportForMe;