import React, {useState} from "react";
import Eligibility from "../../models/CancerNavigatorEligibility";
import {Tab, TabContent, Tabs} from "../Shared/Tabs";
import {CancerNavigatorServicesEnums} from '../../models/CancerNavigatorServicesEnums';
import CashClaimService from "./CashClaimService";
import ReframeAccessSupportForMe from "./ReframeAccessSupportForMe";
import ReframeSupportForMe from "./ReframeSupportForMe";
import CancerCashIcon from "../../images/cancer-cash.svg";
import CancerSupportIcon from "../../images/cancer-support.svg";
import {formatViewDate} from "../../utils/dateFormat";
import {composeUrl} from "../../utils/api";
import {useAuth} from "react-oidc-context";

interface MyServicesProps {
    eligibility: Eligibility;
    onRestartEligibility: () => void;
}

function MyServices({eligibility, onRestartEligibility}: MyServicesProps) {
    const auth = useAuth();
    const [memberStartDate, setMemberStartDate] = useState(new Date());

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(composeUrl('profile'), {headers: [['Authorization', `Bearer ${auth.user?.access_token}`]]});
            const member = await response.json();
            setMemberStartDate(member.startDate);
        }
        void fetchData();
    }, [auth.user]);

    const handleClickResetAnswers = () => {
        onRestartEligibility();
    };

    const getServiceAvailabilityMessage = () => {
        const diagnosisDate = new Date(eligibility.diagnosisDate);
        const diagnosisDateString = formatViewDate(diagnosisDate);
        const memberStart = new Date(memberStartDate);
        const sixMonthsBeforeMemberStart = new Date(memberStart.setMonth(memberStart.getMonth() - 6));

        if(eligibility.diagnosisDate == null){
            return (
                <>
                    Service availability is based on your concern that you might have cancer,
                    but you have not yet been formally diagnosed.
                    If this is incorrect or your circumstances have changed,
                    <a onClick={handleClickResetAnswers}
                       className="text-blue-600 hover:text-blue-800 cursor-pointer"> click here.</a>
                </>
            );
        }
        else if(diagnosisDate < sixMonthsBeforeMemberStart){
            return (
                <>
                    Service availability is based on your cancer diagnosis on {diagnosisDateString} before
                    becoming a member. If this is incorrect or your circumstances have changed,
                    <a onClick={handleClickResetAnswers}
                       className="text-blue-600 hover:text-blue-800 cursor-pointer"> click here.</a>
                </>
            );
        }
        else if (eligibility.answers.IsIneligibleCancerType) {
            return (
                <>
                    Service availability is based on your cancer diagnosis on {diagnosisDateString} with a type of
                    cancer that's an
                    <a href="/documents" target="_blank"
                       className="text-blue-600 hover:text-blue-800"> ineligible type</a> for the
                    cash benefit. If this is incorrect or your circumstances have changed,
                    <a onClick={handleClickResetAnswers}
                       className="text-blue-600 hover:text-blue-800 cursor-pointer"> click here.</a>
                </>
            );
        } else if (eligibility.answers.HadCancerPreviously) {
            return (
                <>
                    Service availability is based on your cancer diagnosis on {diagnosisDateString} with a previous
                    cancer diagnosis in the last 10 years. If this is incorrect or your circumstances have changed,
                    <a onClick={handleClickResetAnswers}
                       className="text-blue-600 hover:text-blue-800 cursor-pointer"> click here.</a>
                </>
            );
        } else {
            return (
                <>
                    Service availability is based on your cancer diagnosis on {diagnosisDateString}, no previous cancer
                    diagnosis in the last 10 years and the type of cancer you have is not an
                    <a href="/documents" target="_blank"
                       className="text-blue-600 hover:text-blue-800"> ineligible type</a> for the
                    cash benefit. If this is incorrect or your circumstances have changed,
                    <a onClick={handleClickResetAnswers}
                       className="text-blue-600 hover:text-blue-800 cursor-pointer"> click here.</a>
                </>
            );
        }
    };


    const isServiceAvailable = (serviceEnum: CancerNavigatorServicesEnums) => {
        const serviceName = CancerNavigatorServicesEnums[serviceEnum];
        return (
            eligibility.eligibleServices &&
            Array.isArray(eligibility.eligibleServices) &&
            eligibility.eligibleServices.length > 0 &&
            eligibility.eligibleServices.includes(serviceName)
        );
    };

    const renderTabsAndContents = () => {
        const elements = [];

        if (isServiceAvailable(CancerNavigatorServicesEnums.CancerCash)) {
            elements.push(<Tab tabKey="1" name="Cash" icon={CancerCashIcon}/>);
            elements.push(
                <TabContent associatedTabKey="1">
                    <CashClaimService/>
                </TabContent>
            );
        }

        if (isServiceAvailable(CancerNavigatorServicesEnums.ReframeSupport)) {
            elements.push(<Tab tabKey="2" name="Support" icon={CancerSupportIcon}/>);
            elements.push(
                <TabContent associatedTabKey="2">
                    <ReframeSupportForMe />
                </TabContent>
            );
        }
        else
        {
            elements.push(<Tab tabKey="3" name="Access" icon={CancerSupportIcon}/>);
            elements.push(
                <TabContent associatedTabKey="3">
                    <ReframeAccessSupportForMe />
                </TabContent>
            );
        }

        return elements;
    };

    return (
        <div className="flex flex-col gap-xl">
            <div>
                <p>From the eligible options below, please choose the service you'd like to explore.</p>
            </div>
            <div>
                <p className="text-mid-grey leading-5">
                    {getServiceAvailabilityMessage()}
                </p>
            </div>
            <div>
                <Tabs>
                    {renderTabsAndContents()}
                </Tabs>
            </div>
        </div>
    );
}

export default MyServices;