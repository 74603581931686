import React, { useState } from "react";
import CashClaimConfirmation from "./CashClaimConfirmation";
import CashClaimSubmission from "./CashClaimSubmission";

function CashClaim() {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [claimReference, setClaimReference] = useState<string>("");

    const handleSubmit = (referenceNumber: string) => {
        setClaimReference(referenceNumber);
        setIsSubmitted(true);
    }

    return (
        <div className="flex flex-col gap-md max-w-[50rem]">
            {!isSubmitted ? (
                <CashClaimSubmission onSubmit={handleSubmit} />
            ) : (
                <CashClaimConfirmation claimReference={claimReference} />
            )}
        </div>
    );
}

export default CashClaim;