import React, { useState } from 'react';
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

function CookieBanner() {
  const [cookies, setCookie] = useCookies();
  const [consent, setConsent] = useState<boolean>(true);

  let location = useLocation();
  React.useEffect(() => {
    if (cookies['lime-member-cookie-consent'] || location.pathname.includes("/privacyPolicy")) {
      setConsent(true);
    } else {
      setConsent(false);
    }
  }, []);
  const accept = () => {
    setCookie('lime-member-cookie-consent', null, { maxAge: 86400 });
    setConsent(true);
  };
  const cookiePolicyUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/OnCallCookiePolicy.pdf";

  return(
      <div>
          { !consent &&
              <div className="fixed top-0 left-0 z-50 w-full h-full bg-modal-overlay p-md">
                  <div className="bg-button-background-blue border-background-blu rounded-md flex flex-col sm:flex-row gap-md items-center
                        justify-center m-sm p-md sm:gap-xl sm:max-w-screen-md sm:mx-auto">
                      <p className="text-p2 text-center sm:text-left">
                          We use cookies in order to personalise your site experience. Read our&nbsp;
                          <a href={cookiePolicyUrl} target="_blank" className="text-link-blue font-bold">
                              Cookie policy
                          </a>
                      </p>
                      <button className="bg-button-background-blue border-solid border border-primary-text rounded-full
                        text-primary-text w-fit py-xs px-xl" onClick={accept}>
                          Accept
                      </button>
                  </div>
              </div>
          }
      </div>
  );
}

export default CookieBanner;