import React, { useState } from 'react';
import FooterLink from "./FooterLink";
import Logo from '../../images/logo/lime-logo-web-1596-266.png';

function Footer() {
  const [currentYear] = useState(() =>  new Date().getFullYear());

  return (
    <div className="bg-lime-bg text-pure-white p-lg">
      <div className="container mx-auto gap-lg flex flex-col sm:flex-row sm:w-full sm:max-w-screen-xl
        sm:justify-between sm:px-xl sm:py-lg">
        <nav className="text-center sm:text-start">
          <h4 className="font-sans-co font-bold text-h2 mt-0">Company</h4>
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/AboutLimeMember+2.0.pdf">
            About
          </FooterLink>
          |
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Lime+Health+FAQs.pdf">
            FAQ
          </FooterLink>
          |
          <FooterLink
              href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Company/Documents/OnCallAccessibilityStatement.pdf">
            Accessibility Statement
          </FooterLink>
          <br />
          <FooterLink href="mailto:complaints@limehealth.uk">
            Complaints
          </FooterLink>
        </nav>
        <section className="text-center ">
          <div className="w-100 flex items-center justify-center">
            <img src={Logo} alt="logo" className="h-[2.7rem] my-md sm:mt-[0.4375rem] sm:mb-md"/>
          </div>
          <p className="text-p2 mb-md">© {currentYear} Lime Global - All Rights Reserved</p>
        </section>
        <nav className="text-center sm:text-end">
          <h4 className="font-sans-co font-bold text-h2 mt-0">Legal</h4>
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/MemberTermsAndConditions.pdf">
            Terms and Conditions
          </FooterLink>
          |
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/OnCallCookiePolicy.pdf">
            Cookies
          </FooterLink>
          <br />
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/MemberPrivacyPolicy.pdf">
            Privacy Policy
          </FooterLink>
          |
          <FooterLink href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/OnCallSecurityStatement.pdf">
            Security Policy
          </FooterLink>
        </nav>
      </div>
    </div>
  );
}

export default Footer;