import React from "react";
import ErrorOops from "../../images/error-oops.svg";
import {NavLink} from "react-router-dom";
import Footer from "../Footer";

function Error() {
  return (
    <div className="h-full">
      <div className="bg-dark-grey container mx-auto grid sm:grid-rows-[auto_min-content] sm:min-h-full">
        <main className="bg-background-blu min-h-[25rem]">
          <div className="bg-sherpa-blue">
            <img src={ErrorOops} alt="Woman pointing to oops board" className="mx-auto min-w-[20rem] sm:max-w-[42rem] w-[60%] relative -bottom-7" />
          </div>
          <div className="text-center mt-2xl mb-4xl">
            <h3 className="mb-8 sm:text-h2">Sorry something went wrong...</h3>
            <p className="text-p1 sm:text-h4 sm:font-normal mb-8 max-w-xl mx-auto px-8">It's us, not you. There's an issue with our site that is preventing you from going any further.</p>
            <div className="w-fit mx-auto text-left">
              <h3 className="sm:text-h2 leading-10">Helpful Links</h3>
              <NavLink to={"/dashboard"} className="text-h4 sm:text-h3 font-sans-co mt-2 font-bold block">Dashboard</NavLink>
              <NavLink to={"/documents"} className="text-h4 sm:text-h3 font-sans-co mt-2 font-bold block">Documents</NavLink>
              <NavLink to={"/help"} className="text-h4 sm:text-h3 font-sans-co mt-2 font-bold block">Help</NavLink>
            </div>
          </div>
        </main>
        <footer className="min-h-[5rem]">
          <Footer/>
        </footer>
      </div>
    </div>
  );
}

export default Error;