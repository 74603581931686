import React, {useState} from 'react';
import {useLocation, useLoaderData} from "react-router-dom";
import ProductInfo from "../../components/HealthEssentials/ProductInfo";
import Home from "../../components/HealthEssentials/Home";
import {useAuth} from "react-oidc-context";

function HealthEssentials() {
    const auth = useAuth();
    const { state } = useLocation();

    const showHealthEssentialsHome = state ? state["showHealthEssentialsHome"] ?? false : false;

    return (
        <div>
            { showHealthEssentialsHome ? <Home /> : <ProductInfo /> }
        </div>
    );
}

export default HealthEssentials;