import React from 'react';
import { useLocation } from "react-router-dom";
import Home from "../../components/HealthTracker/Home";
import ProductInfo from "../../components/HealthTracker/ProductInfo";

function HealthTracker(){
    const { state } = useLocation();

    const showHealthTrackerHome = state ? state["showHealthTrackerHome"] ?? false : false;

    return (
        <div>
            { showHealthTrackerHome ? <Home /> : <ProductInfo /> }
        </div>
    );
}

export default HealthTracker;