import React from 'react';

interface Props {
    associatedTabKey: string;
    children: React.ReactNode;
    selectedTabKey?: string;
}

function TabContent(props: Props) {
    return (
        <div className={props.selectedTabKey !== props.associatedTabKey ? "tab-content hidden" : "tab-content"}
             id={`tab-content-${props.associatedTabKey}`}>
            { props.children }
        </div>
    );
}

export default TabContent;