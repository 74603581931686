import React from 'react';
import { Params, useMatches } from "react-router-dom";
import { useNavigate } from "react-router-dom";

interface Match {
    id: string,
    pathname: string,
    params: Params,
    data: any,
    handle: any
}

interface BreadCrumb {
  crumb: string,
  pathname: string,
  state: {}
}

function Breadcrumb() {
    const navigate = useNavigate();

    let matches: Match[] = useMatches();

    let breadcrumbs: BreadCrumb[] = matches
      .filter((match) => Boolean(match.handle?.crumb))
      .map((match) => ({
        crumb: match.handle.crumb(),
        pathname: match.pathname === '/' ? '/dashboard' : match.pathname,
        state: match.handle.state
      }));

    const breadCrumbNavigate = (isActiveCrumb: boolean, pathName: string, state: {}) => {
      if (!isActiveCrumb) {
        navigate(pathName, { state: state });
      }
    }

    return (
      <div className="text-h4 font-normal text-light-grey mb-4">
        {breadcrumbs.map((breadcrumb, index) => (
          <span key={index}>
            <span className={index === breadcrumbs.length - 1 ? "text-lime-green" : "text-light-grey hover:cursor-pointer"}
                  onClick={() => breadCrumbNavigate(index === breadcrumbs.length - 1, breadcrumb.pathname, breadcrumb.state)}>
              {breadcrumb.crumb}
            </span>
            {index < breadcrumbs.length - 1 && " / "}
          </span>
        ))}
      </div>
    );
}

export default Breadcrumb;