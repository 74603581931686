import React, { useState } from 'react';
import OptOutModal from "../OptOut/OptOutModal";
import { composeUrl } from "../../utils/api";
import { useAuth } from "react-oidc-context";

function Help() {
  const auth = useAuth();
  const [isOptOutModalOpen, setIsOptOutModalOpen] = React.useState(false);
  const [isEligibleForOptOut, setIsEligibleForOptOut] = useState(false);
  const [memberStartDate, setMemberStartDate] = useState(new Date());

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(composeUrl('profile'), { headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}`]]});
      const member = await response.json();
      setIsEligibleForOptOut(member.isEligibleForOptOut);
      setMemberStartDate(member.startDate);
    }
    void fetchData();
  }, [auth.user]);

  function openOptOutModal() {
    setIsOptOutModalOpen(true);
  }

  return (
    <div>
      <h2 className="sm:text-h1 pb-md">Help</h2>
      <div className="border-t-[1px] border-mid-grey py-md">
        <h4 className="sm:text-h3">Guidance</h4>
        <a href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Lime+Health+FAQs.pdf" target="_blank"
           className="block mb-sm">
          Lime FAQs.pdf
        </a>
      </div>
      <div className="border-t-[1px] border-mid-grey py-md">
        <h4 className="sm:text-h3">Contact Us</h4>
        <p className="mb-md">
          Our team are here to help you with any queries or problems
        </p>
        <a className="w-fit block px-lg py-sm font-bold text-primary-text visited:text-primary-text active:text-primary-text bg-button-background-blue rounded-md font-sans-co"
           href="mailto:contact@limehealth.uk">
          Send Email
        </a>
      </div>
      <div className="border-t-[1px] border-mid-grey py-md">
        <h4 className="sm:text-h3">Data Privacy</h4>
        <p className="mb-md">
          Our team are here to help you with any data, privacy queries or problems
        </p>
        <a className="w-fit block px-lg py-sm font-bold text-primary-text visited:text-primary-text active:text-primary-text bg-button-background-blue rounded-md font-sans-co"
           href="mailto:privacy@limehealth.uk">
          Send Email
        </a>
      </div>
      <div className="border-t-[1px] border-mid-grey py-md">
        <h4 className="sm:text-h3">Complaints</h4>
        <p className="mb-sm">We know sometime things can go wrong</p>
        <p>complaints@limehealth.uk</p>
      </div>
      { isEligibleForOptOut && <div className="border-t-[1px] border-mid-grey py-md">
          <h4 className="sm:text-h3 whitespace-nowrap">Opt-Out</h4>
          <p className="mb-md">
            If you would rather opt out of using Lime altogether, let us know here
          </p>
          <button
            className="w-fit block px-xl py-sm font-bold text-primary-text visited:text-primary-text active:text-primary-text border-background-blu bg-button-background-blue rounded-md font-sans-co whitespace-nowrap"
            onClick={openOptOutModal}>
            Opt-Out
          </button>
          <OptOutModal isOpen={isOptOutModalOpen} setIsOpen={setIsOptOutModalOpen} startDate={memberStartDate} />
        </div>}
    </div>
  );
}

export default Help;