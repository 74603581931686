import React from 'react';
import {useAuth} from "react-oidc-context";
import {useNavigate} from "react-router-dom";
import DashboardTilePrimary from "./DashboardTilePrimary";
import imgOnCall from "../../images/dashboard/oncall.png";
import DashboardProducts from "./DashboardProducts";
import DashboardOtherTiles from "./DashboardOtherTiles";
import DashboardPictures from "./DashboardPictures";
function Dashboard() {
    const auth = useAuth();
    const navigate = useNavigate();

    return (
        <div className="flex flex-col gap-md lg:gap-xl">
            <div className="w-full">
                <DashboardTilePrimary
                    title={`Hello ${auth.user?.profile.nickname},`}
                    imageUrl={imgOnCall}
                >
                    <strong>This is your healthcare portal, Lime Health OnCall, </strong>
                    <span>
            where you can book and manage your appointments, use your workplace
            healthcare benefits.
          </span>
                </DashboardTilePrimary>
            </div>
            <div className="flex flex-col lg:grid lg:grid-cols-12 w-full">
                <div id="dashTileArea" className="w-full pb-10 lg:col-span-8 lg:col-start-2 2xl:col-span-9 2xl:col-start-1">
                    <DashboardProducts auth={auth}/>
                    <DashboardOtherTiles/>
                </div>
                <div id="dashPicArea" className="hidden lg:block lg:col-span-3 lg:col-start-10">
                    <DashboardPictures/>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;