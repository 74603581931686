import React, { useEffect, useState } from 'react';
import Gauge from 'react-gauge-component';
import { BiometricArcComponentProps } from './HealthTrackerConstants';

const BiometricArc: React.FC<BiometricArcComponentProps> = ({
        value = 0,
        animated = true,
        animationDuration = 500,
        arcs = [],
    }) => {
    const [gaugeValue, setGaugeValue] = useState<number>(0);
    const minValue = arcs.length > 0 ? arcs[0].min : 0;
    const maxValue = arcs.length > 0 ? arcs[arcs.length - 1].max : 100;

    useEffect(() => {
        if (animated) {
            animateValue(value);
        } else {
            setGaugeValue(value);
        }
    }, [value]);

    const animateValue = (targetValue: number) => {
        let startValue = gaugeValue;
        let startTime: number | null = null;

        const animate = (timestamp: number) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / animationDuration, 1);
            const interpolatedValue = startValue + progress * (targetValue - startValue);
            setGaugeValue(interpolatedValue);

            if (progress < 1) {
                requestAnimationFrame(animate);
            } else {
                setGaugeValue(targetValue);
            }
        };

        requestAnimationFrame(animate);
    };

    const subArcs = arcs.map((arc) => ({
        limit: arc.max,
        color: arc.color,
    }));

    return (
        <div className="custom-gauge-container">
            <Gauge
                value={gaugeValue}
                minValue={minValue}
                maxValue={maxValue}
                type="radial"
                arc={{
                    colorArray: subArcs.map((arc) => arc.color),
                    subArcs: subArcs,
                    padding: 0.05,
                    width: 0.4,
                    cornerRadius: 7,
                }}
                pointer={{
                    type: 'needle',
                    animate: true,
                    elastic: true,
                    color: '#15233b',
                    baseColor: '#ffffff',
                }}
                className="arc-gauge"
            />
        </div>
    );
};

export default BiometricArc;
