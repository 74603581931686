import React, { useState } from "react";
import Logo from "../../images/lime-dark.svg"
import { useAuth } from "react-oidc-context";
import { getClaimValue } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";

function WelcomeHeader() {
  const auth = useAuth()
  const [organisationName] = useState(getClaimValue(auth.user, LimeClaimTypes.OrganisationName));
  return (
    <header className="bg-wave bg-[length:100%_100%] bg-no-repeat bg-pure-white pb-lg row-1 w-full relative
      col-start-1 col-span-4 sm:col-span-12 flex align-items-center content-center">
      <img src={Logo} alt="lime logo" className="h-10 sm:h-14 mt-md mb-auto ml-xl sm:ml-2xl sm:mt-xl z-10"/>
      <h3 className="flex-grow text-center text-p1 sm:text-h3 ml-md sm:ml-xl mt-md sm:mt-xl mb-auto
      max-w-[10rem] sm:max-w-[16rem] text-deep-blue z-10">
        In partnership with {organisationName}
      </h3>
    </header>
  );
}

export default WelcomeHeader;