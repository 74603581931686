import React from 'react';
import {useNavigate} from "react-router-dom";
import OptOutCard from '../OptOut/OptOutCard';
import ProductHeader from "../Product/ProductHeader";
import imgCalculator from "../../images/health-essentials/calculator.png";
import imgInfo from "../../images/info_icon.png";
import imgNet from "../../images/health-essentials/health-essentials-net.png";
import imgEmed from "../../images/health-essentials/emed_logo.png";

function ProductInfo() {
    const navigate = useNavigate();

    const navigateToHome = () => navigate("/HealthEssentials", {state: {showHealthEssentialsHome: true}});
    const navigateToDocs = () => navigate("/documents");
    const summaryUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Essentials+Service+Summary.pdf";
    const faqsUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Member+Booking+FAQs.pdf";

    return (
        <div>
            <div className="pt-sm sm:pt-lg mb-xl">
                <div className="grid grid-cols-12 w-full gap-y-2 md:gap-y-4">
                    <ProductHeader
                        productName="Health Essentials"
                        text="To help us find you the most appropriate clinical help, you'll be asked to complete a few questions."
                        imgSrc={imgCalculator}
                    />

                    <div className="sm:mb-lg col-span-12 md:col-span-6 px-2 order-3 md:order-none md:row-start-2 row-span-2">
                        <img src={imgNet} alt="Health Essentials" className="w-full h-auto"/>
                    </div>

                    <div className="productAction px-2 mb-2 md:mb-lg text-center col-span-12 md:col-span-3 order-4 md:order-none md:row-start-2 md:h-full">
                        <div className="md:h-full">
                            <span
                                onClick={navigateToHome}
                                rel="noreferrer"
                                className="rounded-xl bg-lime-orange p-4 font-medium text-lime-bg items-center
                            space-x-4 cursor-pointer flex justify-center md:justify-between xl:justify-center hover:no-underline h-full text-center"
                            >
                            <h2 className="text-lime-blue text-p0 md:text-2xl md:text-4xl xl:text-h1
                                flex flex-row align-middle md:flex-col productPageAction">
                                <strong className="me-1">Start /</strong>
                                <span className="font-extralight">Manage Booking</span>
                            </h2>
                        </span>
                        </div>
                    </div>

                    <div className="productAction px-2 md:mb-lg col-span-12 md:col-span-3 order-5 md:order-none md:row-start-2 md:h-full">
                        <div className="md:h-full">
                            <a
                                href={faqsUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="rounded-xl bg-lime-fg p-4 font-medium text-lime-bg items-center
                                    space-x-4 cursor-pointer flex justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                            >
                                <div
                                    className="text-center flex flex-row justify-between
                                        md:flex-col items-center md:justify-center w-full h-full ">
                                    <h3 className="text-lime-blue md:mb-4 text-h4 md:text-h2 productPageAction">Booking FAQ</h3>
                                    <img src={imgInfo} alt="info" className="w-10 h-10 md:w-24 md:h-24"/>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="productAction px-2 md:mb-lg col-span-12 md:col-span-6 xl:col-span-3 order-2 md:order-none md:row-start-4 xl:row-start-3 md:h-full">
                        <div className="md:h-full">
                            <a
                                href={summaryUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="rounded-xl bg-lime-turk p-4 font-medium text-lime-bg items-center
                                    space-x-4 cursor-pointer flex justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                            >
                                <div
                                    className="text-center flex flex-row justify-between
                                        md:flex-col items-center md:justify-center w-full h-full ">
                                    <h3 className="text-lime-blue md:mb-4 text-h4 md:text-h2 productPageAction">Service Summary</h3>
                                    <img src={imgInfo} alt="info" className="w-10 h-10 md:w-24 md:h-24"/>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="productAction px-2 md:mb-lg col-span-12 md:col-span-6 xl:col-span-3 order-6 md:order-none md:row-start-4 xl:row-start-3 md:h-full productHeaderTriangleUp mt-xl md:mt-0">
                        <div className="md:h-full">
                           <span
                               rel="noreferrer"
                               className="rounded-xl bg-lime-grey-dark p-4 font-medium text-lime-bg items-center
                                space-x-4 flex justify-between hover:no-underline
                                visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                           >
                            <div className="flex flex-col items-center text-center px-2">
                                <div className="mb-2">
                                    <p className="text-p2 md:text-p1">
                                        Lime Health has partnered with a healthcare specialist called eMed.
                                        eMed is CQC (Care Quality Commission) regulated and offers a
                                        comprehensive range of clinical services 24/7.
                                    </p>
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src={imgEmed} alt="eMed" className="w-20 h-20"/>
                                </div>
                            </div>
                        </span>
                        </div>
                    </div>
                </div>
            </div>

            <OptOutCard/>
        </div>
    );
}

export default ProductInfo;