import React from 'react';
import Logo from '../../images/logo/lime-logo-web-1596-266.png';
import Dashboard from '../../images/dashboard/dashboard_white.png';
import LogOut from '../../images/dashboard/white_exit.png';
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";


function HeaderDesktop() {
    const auth = useAuth();
    const navigate = useNavigate();

    // Function to handle navigation to the home page
    const navigateToHome = () => {
        navigate("/dashboard");
    };

    return (
        <div className="w-full">
            <div className="container px-5 mx-auto w-auto flex justify-between font-sans-co text-pure-white">
                <div className="flex items-center">
                    <img src={Logo} alt="logo" className="h-[2.7rem] my-md sm:mt-[0.4375rem] sm:mb-md me-4 pt-1 cursor-pointer"
                         onClick={navigateToHome} />
                    <span className="ms-10 text-h4 md:text-h2 cursor-pointer" onClick={navigateToHome}>
                        <img alt="Log out" src={Dashboard} className={'me-3 inline-block h-10'}/>
                        Dashboard
                    </span>
                </div>
                <div className="flex items-center">
                    <span className="text-h4 md:text-h2 cursor-pointer" onClick={() => auth.signoutRedirect()}>
                        <img alt="Log out" src={LogOut} className={'me-3 inline-block h-10'}/>
                        Log Out
                    </span>
                </div>
            </div>
        </div>
    );
}

export default HeaderDesktop;