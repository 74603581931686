import React from 'react';

interface Props {
  onClick: any,
  children: React.ReactNode;
}

function NavBarAction({onClick, children}: Props) {
  const click = (e: any) => {
    e.preventDefault();
    onClick();
  }
  return (
    <li>
      <a href="#" className="flex w-fit sm:w-full block px-sm py-xs sm:py-sm mb-md font-bold font-sans-co text-primary-text
                             visited:text-primary-text active:text-primary-text bg-pure-white hover:no-underline hover:bg-super-light-grey
                             rounded-md sm:rounded-lg border-solid border-4 border-pure-white"
         onClick={(e) => click(e) }>
        {children}
      </a>
    </li>
  );
}

export default NavBarAction;