import React from 'react';
import nurse2 from "../../images/dashboard/nurse_2.png";
import nurse3 from "../../images/dashboard/nurse_3.png";
import nurseFullBody from "../../images/dashboard/nurse_full_body.png";

function DashboardPictures() {

    return (
        <div className="flex flex-row gap-md h-full items-end">
            <div className="w-full flex justify-end align-end pe-5">
                <img src={nurse3} alt="Nurse" style={{height: '580px', width: '200px'}}/>
            </div>
        </div>
    );
}

export default DashboardPictures;