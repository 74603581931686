import React from 'react';
import BiometricArc from './BiometricArc';
import { OverallArcs, HealthScoreOverallGaugeProps } from './HealthTrackerConstants';
import {healthTrackerDocs} from "../Documents/docs";

const HealthScoreOverallGauge: React.FC<HealthScoreOverallGaugeProps> = ({
                                                                             value,
                                                                             benchmark,
                                                                             calculationDate,
                                                                         }) => {
    const isNullOrWhitespace = (str: string | undefined | null): boolean => {
        return !str || str.trim() === '';
    };
    const date = new Date(calculationDate);
    const getOrdinalSuffix = (day: number): string => {
        const j = day % 10,
            k = day % 100;
        if (j === 1 && k !== 11) {
            return day + 'st';
        }
        if (j === 2 && k !== 12) {
            return day + 'nd';
        }
        if (j === 3 && k !== 13) {
            return day + 'rd';
        }
        return day + 'th';
    };
    const dayWithSuffix = getOrdinalSuffix(date.getDate());
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const formattedDate = `${dayWithSuffix} ${month} ${year}`;
    
    return (
        <div className="text-center md:py-md">
            <div className="bg-lime-fg-light p-md">
                <div>
                    <div className="text-xl font-medium mb-md m-auto" style={{ maxWidth: '350px' }}>
                        <BiometricArc
                            value={value}
                            arcs={OverallArcs}
                            animated={true}
                            animationDuration={1000}
                        />
                    </div>
                </div>
                <div className="mb-md text-xl font-medium">
                    <p className="text-p05">
                        Score calculated on {formattedDate}
                    </p>
                </div>
                <div>
                    <a className="font-extrabold bg-sherpa-blue text-h2 mb-xl rounded-xl"
                       target="_blank"
                       rel="noreferrer"
                       href={healthTrackerDocs.filter(x => x.name === 'General FAQs').map(x => x.href).at(0)}>
                        <span className="px-xl py-lg text-pure-white">FAQ</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default HealthScoreOverallGauge;
