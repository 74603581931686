import React, { useState } from 'react';
import BiometricArc from './BiometricArc';
import { levelColors, HealthScoreBiometricsProps } from './HealthTrackerConstants';

function HealthScoreBiometrics({ measurements }: HealthScoreBiometricsProps) {
    return (
        <div>
            <h2 className="text-sherpa-blue text-h2 mb-4">Your Measurements</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {measurements.map((measurement, index) => {
                    const arcs = measurement.ranges.map((range) => {
                        const color = levelColors[range.rangeLevel];
                        return {
                            min: range.minValue,
                            max: range.maxValue,
                            color,
                            disabledColor: color,
                        };
                    });

                    return (
                        <div key={index} className="mb-4 bg-lime-fg-light p-4">
                            <h4 className="text-xl font-medium mb-4 text-sherpa-blue">{measurement.signalName}</h4>
                            <BiometricArc
                                value={measurement.value}
                                arcs={arcs}
                                animated={true}
                                animationDuration={1000}
                            />
                            <DescriptionWithReadMore
                                description={measurement.signalDescription}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

interface DescriptionWithReadMoreProps {
    description: string;
}

function DescriptionWithReadMore({ description }: DescriptionWithReadMoreProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpanded = () => setIsExpanded(!isExpanded);
    
    const isLongDescription = description.length > 150;

    return (
        <div>
            <p className={`text-sm ${isLongDescription && !isExpanded ? 'line-clamp-3' : ''}`}>
                {description}
            </p>
            
            {isLongDescription && (
                <span
                    className="text-lime-blue mt-2 cursor-pointer"
                    onClick={toggleExpanded}
                >
                    {isExpanded ? 'Read less' : 'Read more'}
                </span>
            )}
        </div>
    );
}

export default HealthScoreBiometrics;
