import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { DayPicker } from 'react-day-picker';
import StateButton from "../Shared/StateButton/StateButton";
import { useNavigate } from "react-router-dom";
import RadioGroup from "../Shared/Radio/RadioGroup";
import { composeUrl } from "../../utils/api";
import { useAuth } from "react-oidc-context";
import {CancerNavigatorServicesEnums} from '../../models/CancerNavigatorServicesEnums';
import {CancerNavigatorQuestionCodeEnums} from '../../models/CancerNavigatorQuestionCodeEnums';
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";

interface EligibilityFormData {
    diagnosisDate: Date | undefined,
    answers: Record<string, boolean>
}

interface EligibilityJourneyProps {
    onEligibilityComplete: (eligibilityData?: any) => void;
}

function EligibilityJourney({ onEligibilityComplete }: EligibilityJourneyProps) {
    const auth = useAuth();
    const navigate = useNavigate()

    const {handleSubmit, formState} = useForm<EligibilityFormData>();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    const [hasBeenDiagnosed, setHasBeenDiagnosed] = useState<boolean>();
    const [hadCancerPreviously, setHadCancerPreviously] = useState<boolean>();
    const [isIneligibleCancerType, setIsIneligibleCancerType] = useState<boolean>();
    const [selectedDateValid, setSelectedDateValid] = useState<boolean>();
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [loadingSelectedDate, setLoadingSelectedDate] = useState<boolean>(false);

    const handleHasBeenDiagnosed = (newBooleanValue: boolean) => {
        setHasBeenDiagnosed(newBooleanValue);
    }

    const handleHadCancerPreviously = (selectedOption: string) => {
        const asBoolean = selectedOption === "true";
        setHadCancerPreviously(asBoolean);
    }

    const handleIsIneligibleCancerType = (selectedOption: string) => {
        const asBoolean = selectedOption === "true";
        setIsIneligibleCancerType(asBoolean);
    }

    const onSelectedDateChange = async (day: Date) => {
        setLoadingSelectedDate(true);
        const updatedDay = new Date(day.setHours(15));
        await fetch(composeUrl(`cancerNavigator/eligibility/answers/${updatedDay.toISOString()}`),
            { headers: [[ 'Authorization', `Bearer ${auth.user?.access_token}`]]})
            .then(response =>
            {
                if (response.ok) {
                    return response.json() as Promise<CancerNavigatorServicesEnums[]>;
                } else {
                    return [];
                }
            })
            .then(servicesCodes => {
                setSelectedDateValid(servicesCodes.includes(CancerNavigatorServicesEnums.ReframeSupport));
                setSelectedDate(updatedDay);
            })
            .finally(() => {
                setLoadingSelectedDate(false);
            })
    }

    const confirmButtonDisabled = () => {

        if (hasBeenDiagnosed === undefined) {
            return true;
        }

        if (hasBeenDiagnosed
            && selectedDate === undefined) {
            return true;
        }

        if (hasBeenDiagnosed
            && selectedDateValid
            && hadCancerPreviously === undefined) {
            return true;
        }

        return !!(hasBeenDiagnosed
            && selectedDateValid
            && !hadCancerPreviously
            && isIneligibleCancerType === undefined);
    }

    const onSubmit = () => {
        setSubmitLoading(true);

        const answers: Record<string, boolean> = {
            [CancerNavigatorQuestionCodeEnums.HasBeenDiagnosed.toString()]: hasBeenDiagnosed ?? false
        }

        if (hasBeenDiagnosed
            && hadCancerPreviously !== undefined) {
            answers[CancerNavigatorQuestionCodeEnums.HadCancerPreviously.toString()] = hadCancerPreviously ?? false;
        }

        if (hasBeenDiagnosed
            && !hadCancerPreviously
            && isIneligibleCancerType !== undefined) {
            answers[CancerNavigatorQuestionCodeEnums.IsIneligibleCancerType.toString()] = isIneligibleCancerType ?? false;
        }

        let eligibilityFormData: EligibilityFormData = {
            diagnosisDate: selectedDate,
            answers: answers
        };

        fetch(composeUrl(`cancerNavigator/eligibility/answers`),
            {
                headers: [['Authorization', `Bearer ${auth.user?.access_token}`], ['Content-Type', 'application/json']],
                method: 'PUT',
                body: JSON.stringify(eligibilityFormData)
            })
            .then(response => response)
            .then(data => {
                if (data.ok) {
                    onEligibilityComplete(eligibilityFormData);
                }
            });
    };

    function restart() {
        // clear data
        setHasBeenDiagnosed(undefined);
        setHadCancerPreviously(undefined);
        setIsIneligibleCancerType(undefined);
        setSelectedDateValid(undefined);
        setSelectedDate(undefined);
    }

    return (
        <div className="flex flex-col gap-xl">
            <p className="">
                To help us determine which cancer services are available to you, we need to ask you a few simple
                questions.
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-xl">
                <div className="flex flex-col gap-md">
                    <p className="font-bold">
                        Please choose the relevant option:
                    </p>
                    <div className="flex flex-col gap-sm px-sm lg:flex-row">
                        <button
                            type={"button"}
                            className={"flex border w-full max-w-full text-primary-text rounded-md border-solid border-primary-text py-md bg-pure-white items-center justify-center focus:bg-soft-green hover:bg-soft-green disabled:bg-pure-white disabled:text-light-grey" + (hasBeenDiagnosed === true ? ' bg-soft-green' : '')}
                            onClick={(event) => {
                                handleHasBeenDiagnosed(true);
                                event.preventDefault()
                            }}>
                            I have been diagnosed with cancer
                        </button>
                        <button
                            type={"button"}
                            className={"flex border w-full max-w-full text-primary-text rounded-md border-solid border-primary-text py-md bg-pure-white items-center justify-center focus:bg-soft-green hover:bg-soft-green disabled:bg-pure-white disabled:text-light-grey" + (hasBeenDiagnosed === false ? ' bg-soft-green' : '')}
                            onClick={(event) => {
                                handleHasBeenDiagnosed(false);
                                event.preventDefault()
                            }}>
                            I’m concerned I might have cancer
                        </button>
                    </div>
                </div>
                {
                    (hasBeenDiagnosed) &&
                    <div className="flex flex-col gap-md">
                        <p className="font-bold">
                            When were you diagnosed?
                        </p>
                        <div className="flex rounded-md">
                            <LoadingSpinner loading={loadingSelectedDate}>
                                <DayPicker
                                    mode="single"
                                    selected={selectedDate}
                                    onDayClick={onSelectedDateChange}
                                    disabled={{after: new Date()}}
                                    modifiersClassNames={{
                                        selected: '!bg-soft-green',
                                        disabled: '!bg-pure-white !text-dark-grey'
                                    }}
                                />
                            </LoadingSpinner>
                        </div>
                    </div>
                }
                {
                    (hasBeenDiagnosed && selectedDateValid) &&
                    <div className="flex flex-col gap-md">
                        <p className="font-bold">
                            Have you previously been diagnosed with cancer in the last 10 years?
                        </p>
                        <RadioGroup
                            options={[
                                {name: "hadCancerPreviously", value: "true", label: "Yes"},
                                {name: "hadCancerPreviously", value: "false", label: "No"}
                            ]}
                            onChange={handleHadCancerPreviously}
                        />
                    </div>
                }
                {
                    (hasBeenDiagnosed && selectedDateValid && (hadCancerPreviously !== undefined && !hadCancerPreviously)) &&
                    <div className="flex flex-col gap-md">
                        <p className="font-bold">
                            Have you been diagnosed with any of the following types of cancer?
                        </p>
                        <ul className="list-disc list-outside ml-xl">
                            <li>
                                <b>Skin Cancer</b>
                                <br/>
                                <span className="text-mid-grey">
                                Melanoma, non-melanoma or squamous cell cancer, unless there is evidence of secondary spread to organs or lymph nodes
                            </span>
                            </li>
                            <br/>
                            <li>
                                <b>Prostate Cancer</b>
                                <br/>
                                <span className="text-mid-grey">
                                Unless active treatment has been recommended by an NHS specialist
                            </span>
                            </li>
                        </ul>
                        <RadioGroup
                            options={[
                                {name: "isIneligibleCancerType", value: "true", label: "Yes"},
                                {name: "isIneligibleCancerType", value: "false", label: "No"}
                            ]}
                            onChange={handleIsIneligibleCancerType}
                        />
                    </div>
                }
                <div className="flex mb-2xl gap-md">
                    {
                        hasBeenDiagnosed === true && <button className="secondary" onClick={restart}>Restart</button>
                    }
                    {
                        hasBeenDiagnosed !== undefined && <StateButton loading={submitLoading} submit={true}
                                                                       disabled={confirmButtonDisabled()}>Confirm</StateButton>
                    }
                </div>
            </form>
        </div>
    );
}

export default EligibilityJourney;