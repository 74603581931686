import React from 'react';

interface Props {
    name: string;
    tabKey: string;
    disabled?: boolean;
    tabSelected?(tabKey: string): void;
    selectedTabKey?: string;
    icon?: string;
}

function Tab(props: Props) {
    const handleClick = () => {
        if (props.tabSelected && !props.disabled) {
            props.tabSelected(props.tabKey);
        }
    }

    const computeClassName = () => {
        let result = `tab text-h4 flex items-center`;
        result += !props.disabled ? " hover:cursor-pointer" : " text-light-grey font-normal";
        if (props.selectedTabKey === props.tabKey) {
            result += " border-b-4 border-primary-text font-bold";
        } else {
            result += " font-normal";
        }
        return result;
    }

    return (
        <div className={computeClassName()} id={`tab-${props.tabKey}`} onClick={handleClick}>
            {props.icon && <img src={props.icon} alt="" className="icon-class mr-2" />}
            {props.name}
        </div>
    )
}

export default Tab;