import React, {useState} from 'react';
import {useAuth} from "react-oidc-context"
import '../styles/index.css';
import 'react-day-picker/dist/style.css';
import {claimValueContains, getClaimValue} from "../utils/authUser";
import LimeClaimTypes from "../models/limeClaimTypes";
import UserType from "../models/userType";
import OrganisationUserType from "../models/organisationUserType";
import {User} from "oidc-client-ts";
import WelcomeJourney from "./Welcome";
import Footer from "./Footer";
import MobileNavBar from "./Navigation/MobileNavBar";
import HeaderDesktop from "./Header/HeaderDesktop";
import {Outlet, useNavigate} from "react-router-dom";
import CookieBanner from "./Shared/CookieBanner/CookieBanner";
import {ScrollRestoration} from "react-router-dom";
import {limeApiFetch, limeApiGet} from "../utils/api";
import {sleepFor} from "../utils/async";
import LoadingSpinner from "./Shared/LoadingSpinner/LoadingSpinner";
import Breadcrumb from "./Shared/Breadcrumb/Breadcrumb";

export interface GetUserTerms {
    accepted: boolean
}

function App() {
    const navigate = useNavigate()
    const auth = useAuth()
    const [acceptedTerms, setAcceptedTerms] = useState<boolean | null>(null);

    async function acceptTerms() {
        for (let i = 0; i < 3; i++) {
            try {
                if (i > 0) {
                    await sleepFor(i);
                }
                const termsResponse = await limeApiFetch(`terms`, 'POST', auth);
                if (termsResponse.ok) {
                    setAcceptedTerms(true);
                    return true;
                } else {
                    console.log("Accept terms failed due to unexpected response status: " + termsResponse.status);
                }
            } catch (e) {
                console.error("An error occurred while attempting to accept the terms:", e);
            }
        }
        setAcceptedTerms(true); // Allow the user to continue
        return false;
    }

    React.useEffect(() => {
        if (!auth.isLoading) {
            if (auth.error) {
                void auth.signoutRedirect();
                return;
            }

            if (!auth.isAuthenticated) {
                void auth.signinRedirect({state: window.location.pathname});
            } else {
                let isOrganisationUser = (user: User | null | undefined) => {
                    return getClaimValue(user, LimeClaimTypes.UserType) === UserType.Organisation &&
                        getClaimValue(user, LimeClaimTypes.OrganisationUuid) &&
                        claimValueContains(user, LimeClaimTypes.OrganisationUserType, OrganisationUserType.Member)
                };

                if (isOrganisationUser(auth.user)) {
                    if (window.location.pathname === "" || window.location.pathname === "/") {
                        navigate("/dashboard", {replace: true}); // Redirect straight into the dashboard when entering the default host url
                    } else {
                        navigate(window.location.pathname, {replace: true})
                    }
                } else {
                    navigate("/access-denied", {replace: true});
                }
            }
        }
    }, [auth, navigate]);

    React.useEffect(() => {
        (async () => {
            if (!auth.isLoading && auth.isAuthenticated) {
                try {
                    const terms = await limeApiGet<GetUserTerms>(`terms`, auth);
                    setAcceptedTerms(terms.accepted);
                } catch (error) {
                    console.error("Failed to fetch user terms:", error);
                    setAcceptedTerms(false);
                }
            }
        })();
    }, [auth.isLoading, auth.isAuthenticated]);

    React.useEffect(() => {
        return auth.events.addAccessTokenExpiring(() => {
            void auth.signinRedirect();
        })
    }, [auth.events, auth.signinRedirect]);

    if (auth.isLoading || acceptedTerms == null) {
        return (
            <div className="my-xl">
                <LoadingSpinner loading={true}>&nbsp;</LoadingSpinner>
            </div>
        );
    }

    if (acceptedTerms) {
        return (
            <div className="h-95">
                <header id="layoutHeader"
                        className="bg-lime-bg hidden sm:px-md sm:col-start-2 lg:flex items-center h-[4rem]">
                    <HeaderDesktop/>
                </header>
                <CookieBanner/>
                <div className="relative container mx-auto grid grid-cols-1 sm:min-h-90">
                    <MobileNavBar/>
                    <main id="layoutMain" className="w-full p-md pt-sm sm:pt-sm lg:pb-0 sm:col-start-1 min-h-[25rem]">
                        <Breadcrumb/>
                        <Outlet/>
                    </main>
                </div>
                <footer id="layoutFooter" className="sm:col-span-2 min-h-[5rem]">
                    <Footer/>
                </footer>
                <ScrollRestoration/>
            </div>
        );
    } else {
        return (
            <div>
                <CookieBanner/>
                <WelcomeJourney onAcceptTerms={acceptTerms}/>
            </div>
        );
    }
}

export default App;