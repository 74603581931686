export const HtAgeMin = 16;
export const HtAgeMax = 110;
export const HtHeightMinCm = 50;
export const HtHeightMaxCm = 300;
export const HtWeightMinKg = 10;
export const HtWeightMaxKg = 300;

export const levelColors = {
    'Excellent': '#1c6939', // Dark Green
    'Good': '#39a935',      // Green
    'Fair': '#faea2e',      // Yellow
    'Average': '#f0a500',   // Orange
    'Poor': '#ee0004',      // Red
};

export type RangeLevel = keyof typeof levelColors;

export const OverallArcs = [
    { min: 0, max: 25, color: '#ee0004', disabledColor: '#bfbfbf' },
    { min: 26, max: 50, color: '#f0a500', disabledColor: '#bfbfbf' },
    { min: 51, max: 75, color: '#faea2e', disabledColor: '#bfbfbf' },
    { min: 76, max: 100, color: '#39a935', disabledColor: '#bfbfbf' },
    { min: 101, max: 120, color: '#1c6939', disabledColor: '#bfbfbf' },
];

export interface PageData {
    id: string;
    scanDate: Date;
    createdAt: string;
    updatedAt: string;
    status: string;
    userUuid: string;
    externalUuid: string;
    score: number;
    healthScore: HealthScore;
    measurements: Measurement[];
}

export interface HealthScore {
    score: number;
    previousScore: number | null;
    patientId: string;
    legalReferenceNumber: string;
    healthScoreId: string;
    dateCalculated: string;
    healthScore: number;
    previousHealthScore: number | null;
    benchmark: string;
    hasWearableDevices: boolean;
    totalDataPoints: number;
    conditionsWithMatchedMedication: number;
    conditionsWithoutMedication: number;
    scoreChangedOrHasNudges: any;
    biometricDataPoints: BiometricDataPoint[];
    healthScoreNudges: Nudge[];
    nextDailyRefresh: any;
    nextWeeklyRefresh: any;
    nextMonthlyRefresh: any;
    minorDisclaimerMessage: any;
}

export interface BiometricDataPoint {
    biometric: string;
    score: number;
    impactOrder: number;
    contributingPoints: number;
    resultDate: string;
    riskType: string;
    ruleText: string;
    riskTypeDescription: string;
}

export interface Measurement {
    keyName: string;
    signalName: string;
    signalDescription: string;
    unit: string;
    value: number;
    previousValue?: number | null;
    ranges: Range[];
    displayOrderNumber?: number;
    additionalInfo?: string;
    healthCloudArticleId?: string;
    category?: string;
    alarmLowValue?: number | null;
    alarmHighValue?: number | null;
    acceptedRange?: string;
    acceptableRangeLow?: number;
    acceptableRangeHigh?: number;
    alarmRaised?: any;
    risks?: any;
    references?: Reference[];
}

export interface Arc {
    min: number;
    max: number;
    color: string;
    disabledColor: string;
}

export interface Nudge {
    name?: string;
    message: string;
    nudgeType: string;
}

export interface HealthScoreNudgesProps {
    nudges: Nudge[];
}

export interface Range {
    minValue: number;
    maxValue: number;
    rangeLevel: RangeLevel;
}

export interface Reference {
    title: string;
    url: string;
}

export interface HealthScoreBiometricsProps {
    measurements: Measurement[];
}

export interface BiometricArcComponentProps {
    value: number;
    animated?: boolean;
    animationDuration?: number;
    arcs: Arc[];
}

export type HealthScoreOverallGaugeProps = {
    value: number;
    benchmark: string;
    calculationDate: Date;
};

export interface HealthScoreLink {
    score: number | null;
    limeId: string;
    status: string;
    createdAt: string;
}