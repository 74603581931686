import { useState, useEffect } from 'react';
import { useAuth } from "react-oidc-context";
import { composeUrl } from "./api";
import ProductModel from "../models/ProductModel";

function useProducts(filterOutFutureProducts: boolean = true) {
    const auth = useAuth();
    const [products, setProducts] = useState<ProductModel[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(composeUrl('/products'), { headers: { 'Authorization': `Bearer ${auth.user?.access_token}` } });
                let data = await response.json();

                if (filterOutFutureProducts) {
                    const currentDate = new Date().toISOString().split('T')[0];
                    data = data.filter((p: ProductModel) => {
                        const productDate = new Date(p.startDate).toISOString().split('T')[0];
                        return productDate <= currentDate;
                    });
                }

                setProducts(data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [auth.user?.access_token]);

    return { products, loading };
}

export default useProducts;