import Configuration from "../../../models/Configuration";
import { AuthContextProps } from "react-oidc-context";
import { composeUrl } from "../../../utils/api";

export enum ReframeServiceId
{
    Support = 1,
    AccessForMe = 2,
    AccessForAnother = 3
}

export function openReframeService(
    reframeServiceId: ReframeServiceId,
    auth: AuthContextProps,
    config: Configuration | null
    ) {
    logReframeUsage(reframeServiceId, auth);
    openReframe(reframeServiceId, config);
}

function logReframeUsage(
    reframeServiceId: ReframeServiceId,
    auth: AuthContextProps) {

    void fetch(composeUrl(`cancerNavigator/reframe`),
        {
            headers: [
                ['Authorization', `Bearer ${auth.user?.access_token}`],
                ['Content-Type', 'application/json']],
            method: 'POST',
            body: JSON.stringify({
                reframeServiceId: reframeServiceId
            })
        });
}

function openReframe(
    reframeServiceId: ReframeServiceId,
    config: Configuration | null) {

    if (!(config
        && config.reframeAccess
        && config.reframeAccessOther
        && config.reframeSupport)) {
        console.log("Failed to open Reframe due to bad config.");
        return;
    }

    let fullUrl: string = '';
    switch (reframeServiceId) {
        case ReframeServiceId.AccessForMe:
            fullUrl = config.reframeAccess;
            break;
        case ReframeServiceId.AccessForAnother:
            fullUrl = config.reframeAccessOther;
            break;
        case ReframeServiceId.Support:
            fullUrl = config.reframeSupport;
            break;
    }

    const httpRegex = /^https?:\/\//;
    if (!httpRegex.test(fullUrl)) {
        fullUrl = 'https://' + fullUrl;
    }

    window.open(fullUrl, '_blank');
}