import React, {useState} from "react";
import {composeUrl} from "../../utils/api";
import Eligibility from "../../models/CancerNavigatorEligibility";
import {useAuth} from "react-oidc-context";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";
import RadioGroup from "../Shared/Radio/RadioGroup";
import {useNavigate} from 'react-router-dom';
import {formatViewDate} from "../../utils/dateFormat";
import {CancerNavigatorServicesEnums} from '../../models/CancerNavigatorServicesEnums';
import CashClaimUnavailable from "./CashClaimUnavailable";

interface CashClaimSubmissionProps {
    onSubmit: (referenceNumber: string) => void;
}

function CashClaimSubmission({onSubmit}: CashClaimSubmissionProps) {
    const auth = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [eligibility, setEligibilityAnswers] = useState<Eligibility | null>(null);
    const navigate = useNavigate();
    const [isInformationValid, setIsInformationValid] = useState<boolean>(false);
    const [hasMadeSelection, setHasMadeSelection] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasCompletedSubmission, setHasCompletedSubmission] = useState<boolean>(false);

    const fetchEligibilityAnswers = () => {
        setLoading(true);
        fetch(composeUrl('cancernavigator/eligibility/answers'), {
            headers: [['Authorization', `Bearer ${auth.user?.access_token}`]]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const answersObject: { [key: string]: boolean } = {};
                for (const key in data.answers) {
                    answersObject[key] = data.answers[key];
                }

                const eligibility = new Eligibility(data.createdOn, data.diagnosisDate, answersObject, data.eligibleServices);
                setEligibilityAnswers(eligibility);

                if (!eligibility.eligibleServices.includes(CancerNavigatorServicesEnums[CancerNavigatorServicesEnums.CancerCash])) {
                    navigate('/cancer');
                }
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    React.useEffect(() => {
        fetchEligibilityAnswers();
    }, []);

    const handleCancel = () => {
        navigate('/cancer');
    };

    const handleCreateClaim = async () => {
        setIsSubmitting(true);

        if (!selectedFiles.length) {
            setUploadError("Please attach the required files.");
            setIsSubmitting(false);
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('attachments', file);
        });

        try {
            const response = await fetch('/api/cancerNavigator/cashClaims', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${auth.user?.access_token}`
                },
            });

            if (!response.ok) {
                throw new Error('Server responded with an error.');
            }

            const result = await response.json();
            onSubmit(result.reference);

        } catch (error) {
            console.error('Error submitting claim:', error);
            setUploadError('Error submitting claim. Please try again.');
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const handleInformationValid = (selectedOption: string) => {
        setHasMadeSelection(true);
        setIsInformationValid(selectedOption === "true");
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files);
            setSelectedFiles(filesArray);
            if (filesArray.length > 0) {
                setIsInformationValid(true);
            }
            else {
                setIsInformationValid(false);
            }
        }
    };

    const getMyAnswersMessage = () => {
        if (eligibility == null) {
            return (
                <>
                    <div>
                        <p>Failed to load at this time. Please reload or contact support.</p>
                    </div>
                </>
            );
        } else {
            const diagnosisDate = new Date(eligibility.diagnosisDate);
            const diagnosisDateString = formatViewDate(diagnosisDate);

            return (
                <>
                    <div>
                        <ul className="list-disc ml-4 mb-6">
                            <li>Your were diagnosed with cancer on {diagnosisDateString}</li>
                            {eligibility.answers.HadCancerPreviously === false && (
                                <li>You have not had cancer in the last 10 years</li>
                            )}
                            {eligibility.answers.IsIneligibleCancerType === false && (
                                <li>
                                    You do not have one of the following types of cancer:
                                    <ul className="list-disc ml-5">
                                        <li>
                                            <p>
                                                Skin Cancer<br/>
                                                <span className="text-mid-grey">
                                                    Melanoma, non-melanoma or squamous cell cancer, unless
                                                    there is evidence of secondary spread to organs or lymph nodes
                                                </span>

                                            </p>
                                        </li>
                                        <li>
                                            Prostate Cancer<br/>
                                            <span className="text-mid-grey">
                                                Unless active treatment has been recommended by an NHS specialist
                                            </span>
                                        </li>
                                    </ul>
                                </li>
                            )}
                        </ul>

                        <div className="mb-4">
                            <RadioGroup
                                options={[
                                    {name: "isInfoCorrect", value: "true", label: "Yes"},
                                    {name: "isInfoCorrect", value: "false", label: "No"}
                                ]}
                                onChange={handleInformationValid}
                            />
                        </div>
                    </div>
                </>
            );
        }
    };

    return (
        <div>
            <h3 className="mb-md">New cash claim - Cancer Navigator</h3>
            <h4 className="font-normal mb-md">
                <p>Create a cash claim to receive a cash lump sum by completing the details below</p>
            </h4>
            <div className="mb-md">
                <p><strong>Is the following information correct?</strong></p>
            </div>

            <LoadingSpinner loading={loading}>
                <div>
                    <div className="mb-lg">
                        {getMyAnswersMessage()}
                    </div>
                    {isInformationValid && (
                        <div className="mb-lg">
                            <div className="mb-md">
                                <p><strong>Please upload your cancer diagnosis letter:</strong></p>
                                <p>
                                    We require the full letter to validate your claim.
                                    You can provide multiple images if required and you must
                                    ensure the full letter is legible.
                                </p>
                            </div>
                            <div id="multiFileUpload">
                                <input
                                    type="file"
                                    multiple
                                    accept="
                                    image/jpeg,
                                    image/png,
                                    image/heic,
                                    image/tiff,
                                    image/gif,
                                    application/pdf"
                                    onChange={handleFileSelect}
                                />
                            </div>
                            <div className="mb-lg text-error-red">
                                {uploadError}
                            </div>
                        </div>
                    )}
                    {!isInformationValid && hasMadeSelection && (
                        <div className="mb-lg">
                            <CashClaimUnavailable/>
                        </div>
                    )}
                    {(!hasMadeSelection || (isInformationValid && hasMadeSelection)) && (
                        <div className="mb-lg">
                            <div className="mb-4 ">
                                <button className="mx-sm secondary" onClick={handleCancel}>Cancel</button>
                                <button className="mx-sm"
                                        disabled={!isInformationValid || isSubmitting || hasCompletedSubmission}
                                        onClick={handleCreateClaim}>Create Claim
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </LoadingSpinner>
        </div>
    );
}

export default CashClaimSubmission;