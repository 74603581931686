import React, {FunctionComponent} from "react";
import DocumentItem from "./DocumentItem";

interface Document {
    name: string;
    href: string;
} 
    
interface ProductSectionProps {
    name: string;
    documents: Document[];
}

const ProductSection: FunctionComponent<ProductSectionProps> = (props: ProductSectionProps) => {
    return (
        <>
            <h4 className="py-2">{props.name}</h4>
            <table className="border-t border-mid-grey w-full">
                <thead className="text-left text-p2 lg:text-h1">
                <tr>
                    <th className="py-sm lg:text-h4 w-32 lg:w-36">Download</th>
                    <th className="py-sm lg:text-h4">Name</th>
                </tr>
                </thead>
                <tbody>
                    {props.documents.map((document: Document, index) => (
                        <DocumentItem name={document.name} href={document.href} key={index}></DocumentItem>
                    ))}
                </tbody>
            </table>
        </>
    )
}
export default ProductSection;