import React from 'react';
import Download from "../../images/download.svg"

interface Props {
  name: string;
  href: string;
}

function DocumentItem(props: Props) {
  return (
      <tr className="lg:text-h4 lg:font-normal py-sm">
        <td className="py-sm align-middle">
          <a href={props.href} target="_blank">
            <img src={Download} alt="Download" className="ml-lg lg:ml-xl"/>
          </a>
        </td>
        <td className="py-sm align-middle">
          {props.name}
        </td>
      </tr>
    );
}

export default DocumentItem;