import React from 'react';

interface Props {
    title: string;
    actionText?: string;
    action?: Function;
    imageUrl?: string;
    children: React.ReactNode;
}

function DashboardTilePrimary(props: Props) {
    function imageStyle() {
        return props.imageUrl ? " basis-2/3" : "";
    }

    return (
        <div className={`flex p-md xl:p-lg gap-md rounded-lg shadow-tile justify-between items-stretch bg-lime-fg w-full`}>
            <div className={`flex flex-col justify-between gap-md xl:gap-xl basis-3/3 sm:basis-2/3`}>
                <div className="w-full">
                    <h1 className={`font-sans-co font-bold mb-sm text-h3 lg:text-h2 text-lime-bg`}>
                        {props.title}
                    </h1>
                    <div className="text-p1 sm:text-p0">
                        {props.children}
                    </div>
                </div>
                {props.action && (
                    <button className="text-p1 px-md lg:px-xl py-sm pt-xs w-fit font-sans-co" onClick={() => props.action!()}>
                        {props.actionText}
                    </button>
                )}
            </div>
            {props.imageUrl &&
                <div className="basis-1/3 hidden sm:flex flex-row-reverse items-center">
                    <img src={props.imageUrl} alt={props.title} className="h-full max-h-[6em] xl:max-h-[7.5rem]"/>
                </div>
            }
        </div>
    );
}

export default DashboardTilePrimary;