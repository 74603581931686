import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useAuth} from "react-oidc-context";
import {useNavigate} from "react-router-dom";
import {composeUrl, limeApiFetch} from "../../utils/api";
import RadioGroup from "../Shared/Radio/TraditionalRadioGroup";
import imgInfo from "../../images/info_icon.png";
import imgAlarmClock from "../../images/health-tracker/green-alarm-clock.png";
import imgNoGlassesSign from "../../images/health-tracker/green-no-glasses-sign.png";
import imgNoTalkingSign from "../../images/health-tracker/green-no-talking-sign.png";
import imgRecliningOnChair from "../../images/health-tracker/green-reclining-on-chair.png";
import imgWarningExclamation from "../../images/health-tracker/green-warning-exclamation.png";
import score from "../../images/dashboard/tracker_icon.png";
import alulaLogo from "../../images/health-tracker/alula-logo.svg";
import Checked from "../../images/checkbox-checked.svg";
import Unchecked from "../../images/checkbox-unchecked.svg";
import HealthTrackerBanner from "./HealthTrackerBanner";
import {HtAgeMin, HtAgeMax, HtHeightMinCm, HtHeightMaxCm, HtWeightMinKg, HtWeightMaxKg} from "./HealthTrackerConstants";
import {useViewport} from "react-viewport-hooks";
import {healthTrackerDocs} from "../Documents/docs";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";

enum GenderAtBirth {
    Male = 1,
    Female = 2
}

enum DiabetesStatus {
    No = 1,
    Type1 = 2,
    Type2 = 3
}

interface CreateScanFormData {
    age: number | undefined;
    gender: GenderAtBirth | undefined;
    height: number | undefined;
    weight: number | undefined;
    diabetesStatus: DiabetesStatus | undefined;
    usesBloodPressureMedication: boolean | undefined;
    isSmoker: boolean | undefined;
}

function Home() {

    const {vw} = useViewport();

    const auth = useAuth();
    const navigate = useNavigate();
    
    const navigateToDocs = () => navigate("/documents");

    const [acceptedPrepareForScan, setAcceptedPrepareForScan] = useState<boolean>(false);
    const [acceptedConsentNotice, setAcceptedConsentNotice] = useState<boolean>(false);

    const {handleSubmit} = useForm<CreateScanFormData>();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    const [genderAtBirth, setGenderAtBirth] = useState<GenderAtBirth>();
    const [diabetesStatus, setDiabetesStatus] = useState<DiabetesStatus>();
    const [usesBloodPressureMedication, setUsesBloodPressureMedication] = useState<boolean>();
    const [isSmoker, setIsSmoker] = useState<boolean>();
    const [age, setAge] = useState<number>();
    const [height, setHeight] = useState<number>();
    const [weight, setWeight] = useState<number>();

    const [ageError, setAgeError] = useState<string | null>(null);
    const [heightError, setHeightError] = useState<string | null>(null);
    const [weightError, setWeightError] = useState<string | null>(null);

    const handleGenderAtBirth = (selectedOption: string) => {
        const selectedOptionEnum = GenderAtBirth[selectedOption as keyof typeof GenderAtBirth];
        setGenderAtBirth(selectedOptionEnum);
    };

    const handleDiabetesStatus = (selectedOption: string) => {
        const selectedOptionEnum = DiabetesStatus[selectedOption as keyof typeof DiabetesStatus];
        setDiabetesStatus(selectedOptionEnum);
    };

    const handleUsesBloodPressureMedication = (selectedOption: string) => {
        setUsesBloodPressureMedication(selectedOption === "true");
    };

    const handleIsSmoker = (selectedOption: string) => {
        setIsSmoker(selectedOption === "true");
    };

    const acceptConsentNotice = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setAcceptedConsentNotice(isChecked);

        if (isChecked) {
            try {
                const response = await limeApiFetch(`terms/acceptHealthTracker`, 'POST', auth);
                if (!response.ok) {
                    console.error("Failed to accept Health Tracker terms:", response.status);
                }
            } catch (e) {
                console.error("An error occurred while attempting to accept Health Tracker terms:", e);
            }
        }
    };

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(event.target.value);
        const input = event.target.id;

        switch (input) {
            case "age":
                setAge(newValue);
                if (validateNumber(newValue, HtAgeMin, HtAgeMax)) {
                    setAgeError(`Age must be between ${HtAgeMin} and ${HtAgeMax}`);
                } else {
                    setAgeError(null);
                }
                break;
            case "height":
                setHeight(newValue);
                if (validateNumber(newValue, HtHeightMinCm, HtHeightMaxCm)) {
                    setHeightError(`Height must be between ${HtHeightMinCm} and ${HtHeightMaxCm} cm`);
                } else {
                    setHeightError(null);
                }
                break;
            case "weight":
                setWeight(newValue);
                if (validateNumber(newValue, HtWeightMinKg, HtWeightMaxKg)) {
                    setWeightError(`Weight must be between ${HtWeightMinKg} and ${HtWeightMaxKg} kg`);
                } else {
                    setWeightError(null);
                }
                break;
        }

    };

    const submitButtonDisabled = () => {
        return (
            validateNumber(age, HtAgeMin, HtAgeMax) ||
            validateNumber(height, HtHeightMinCm, HtHeightMaxCm) ||
            validateNumber(weight, HtWeightMinKg, HtWeightMaxKg) ||
            genderAtBirth === undefined ||
            diabetesStatus === undefined ||
            usesBloodPressureMedication === undefined ||
            isSmoker === undefined ||
            !acceptedConsentNotice
        );
    };

    const validateNumber = (value?: number, min?: number, max?: number) => {
        return value === undefined || isNaN(value) || value < (min ?? 0) || value > (max ?? Infinity);
    };

    const onSubmit = () => {
        setSubmitLoading(true);

        let createScanFormData: CreateScanFormData = {
            age: age,
            gender: genderAtBirth,
            height: height,
            weight: weight,
            diabetesStatus: diabetesStatus,
            usesBloodPressureMedication: usesBloodPressureMedication,
            isSmoker: isSmoker
        };

        fetch(composeUrl(`v1/healthTracker`),
            {
                headers: [
                    ["Authorization", `Bearer ${auth.user?.access_token}`],
                    ["Content-Type", "application/json"]
                ],
                method: "POST",
                body: JSON.stringify(createScanFormData)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(data => {
                if (data.assesmentUrl !== undefined) {
                    window.open(data.assesmentUrl, "_self");
                }
            })
            .catch(error => {
                console.error("There was a problem with the fetch operation:", error);
            })
            .finally(() => {
                setSubmitLoading(false);
            });
    };

    function acceptPrepareForScan() {
        setAcceptedPrepareForScan(true);
    }

    let layout;
    if (vw <= 1024 && vw >= 768) {
        layout = 'tablet';
    } else if (vw < 768) {
        layout = 'mobile';
    } else {
        layout = 'desktop';
    }

    return (
        <>
            {(layout === 'desktop') ?
                <>
                    {HealthTrackerBanner()}
                    <div className="flex flex-col sm:basis-3/5 sm:gap-xl h-full">
                        {
                            (!acceptedPrepareForScan) &&
                            <div className="flex flex-col sm:flex-row sm:gap-lg sm:basis-3/5 h-full my-lg">
                                <div className="flex font-medium justify-center mb-md gap-sm sm:gap-xl sm:basis-3/5">
                                    <div className="flex flex-col space-y-4">
                                        <div className="text-center sm:text-start pb-2">
                                            <strong className="text-[1.75em] leading-5 text-sherpa-blue">
                                                Preparing for face scan
                                            </strong>
                                        </div>
                                        <div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-md sm:gap-xl h-full">
                                                <div className="flex gap-md items-center">
                                                    <img
                                                        src={imgWarningExclamation}
                                                        alt="Green circle containing a warning triangle with an exclamation symbol"
                                                        className="w-[3.75em] h-[3.75em]"
                                                    />
                                                    <span className="text-p1 leading-[1.375em]">
                                        <strong>Do not smoke, exercise or consume caffeine or alcohol</strong> for 30 minutes to an hour before the scan
                                    </span>
                                                </div>
                                                <div className="flex gap-md items-center">
                                                    <img
                                                        src={imgRecliningOnChair}
                                                        alt="Green circle containing a person reclining on a chair"
                                                        className="w-[3.75em] h-[3.75em]"
                                                    />
                                                    <span className="text-p1 leading-[1.375em]">
                                        <strong>Relax</strong> in a chair for at least <strong>five minutes before</strong> the test
                                    </span>
                                                </div>
                                                <div className="flex gap-md items-center">
                                                    <img
                                                        src={imgNoTalkingSign}
                                                        alt="Green circle containing a no talking sign"
                                                        className="w-[3.75em] h-[3.75em]"
                                                    />
                                                    <span className="text-p1 leading-[1.375em]">
                                        <strong>Don’t talk</strong> during the scan
                                    </span>
                                                </div>
                                                <div className="flex gap-md items-center">
                                                    <img
                                                        src={imgAlarmClock}
                                                        alt="Green circle containing an alarm clock"
                                                        className="w-[3.75em] h-[3.75em]"
                                                    />
                                                    <span className="text-p1 leading-[1.375em]">
                                        Ideally - take face scan the <strong>same time of the day each month</strong> eg. mid morning or mid afternoon
                                    </span>
                                                </div>
                                                <div className="flex gap-md items-center">
                                                    <img
                                                        src={imgNoGlassesSign}
                                                        alt="Green no glasses sign"
                                                        className="w-[3.75em] h-[3.75em]"
                                                    />
                                                    <span className="text-p1 leading-[1.375em]">
                                        If you wear <strong>glasses</strong>, you will need <strong>to</strong> remove for the face scan
                                    </span>
                                                </div>
                                                <div className="flex flex-row gap-md items-center">
                                                    <div className="flex flex-col basis-3/4 sm:basis-full">
                                                        <div className="align-items-center">
                                                            <div
                                                                onClick={acceptPrepareForScan}
                                                                className="flex rounded-xl bg-lime-orange p-2 font-medium items-center
                                                        space-x-4 cursor-pointer justify-center hover:no-underline h-full text-center  w-full"
                                                            >
                                                        <span className="text-h1 flex flex-row align-middle">
                                                            <strong>NEXT</strong>
                                                        </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="hidden sm:flex flex-row sm:flex-col gap-sm sm:gap-xl items-stretch basis-1/4 ml-auto">
                                    <div className="flex flex-col basis-1/3">
                                        <a href={healthTrackerDocs.filter(x => x.name === 'Face Scan FAQs').map(x => x.href).at(0)}
                                           target="_blank"
                                           rel="noreferrer"
                                           className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                                space-x-4 p-7 sm:p-3 cursor-pointer justify-between hover:no-underline
                                                visited:text-lime-bg active:text-lime-bg font-sans-co w-full h-full">
                                            <div
                                                className="flex flex-col justify-center items-center w-full h-full">
                                            <span
                                                className="text-lime-blue text-[1.25em] sm:text-[1.875em] text-center">
                                                Face Scan FAQ
                                            </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="flex flex-col gap-sm sm:gap-xl basis-2/3">
                                        <a
                                            href={healthTrackerDocs.filter(x => x.name === 'Service Summary').map(x => x.href).at(0)}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                    space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                            <div
                                                className="flex flex-row sm:flex-col justify-center items-center w-full h-full text-center">
                                <span className="text-lime-blue text-[1.25em] sm:text-[1.875em]">
                                    Service Summary
                                </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                            </div>
                                        </a>
                                        <div
                                            onClick={navigateToDocs}
                                            className="flex rounded-xl bg-quartz font-bold text-lime-bg items-center
                                    space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                            <div
                                                className="flex flex-row sm:flex-col justify-center items-center w-full h-full">
                                <span className="text-lime-blue text-[1.25em] sm:text-[1.875em]">
                                    Documents
                                </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (acceptedPrepareForScan) &&
                            <LoadingSpinner loading={submitLoading}>
                                <div className="flex flex-col sm:flex-row sm:gap-xl my-lg">
                                <form onSubmit={handleSubmit(onSubmit)}
                                      className="flex flex-col gap-md sm:gap-xl basis-3/5">
                                    <div className="space-y-4 md:space-y-0 md:flex md:space-x-8">
                                        <div id="htFormInputs"
                                             className="md:flex md:flex-col md:gap-md md:w-1/2 space-y-4">
                                            <div className="flex flex-row gap-md items-center">
                                                <p className="flex-none w-32">Enter your age</p>
                                                <div className="flex-grow">
                                                <input
                                                        className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                        placeholder="Enter age"
                                                        type="number"
                                                        id="age"
                                                        name="age"
                                                        min={HtAgeMin}
                                                        max={HtAgeMax}
                                                        onChange={handleNumberChange}
                                                    />
                                                    {ageError &&
                                                        <span className="text-red-500 text-sm">{ageError}</span>}
                                                </div>
                                            </div>

                                            <div className="flex flex-row gap-md items-center">
                                                <p className="flex-none w-32">Height (in cm)</p>
                                                <div className="flex-grow">
                                                    <input
                                                        className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                        placeholder="Height (cm)"
                                                        type="number"
                                                        id="height"
                                                        name="height"
                                                        min={HtHeightMinCm}
                                                        max={HtHeightMaxCm}
                                                        onChange={handleNumberChange}
                                                    />
                                                    {heightError &&
                                                        <span className="text-red-500 text-sm">{heightError}</span>}
                                                </div>
                                            </div>

                                            <div className="flex flex-row gap-md items-center">
                                                <p className="flex-none w-32">Weight (in kg)</p>
                                                <div className="flex-grow">
                                                    <input
                                                        className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                        placeholder="Weight (kg)"
                                                        type="number"
                                                        id="weight"
                                                        name="weight"
                                                        min={HtWeightMinKg}
                                                        max={HtWeightMaxKg}
                                                        onChange={handleNumberChange}
                                                    />
                                                    {weightError &&
                                                        <span className="text-red-500 text-sm">{weightError}</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <div id="htFormRadios"
                                             className="md:flex md:flex-col md:gap-md md:w-1/2 space-y-4">
                                            <div className="flex flex-row items-center w-full gap-md">
                                                <p className="flex-grow">Gender at birth</p>
                                                <RadioGroup
                                                    options={[
                                                        {name: "genderAtBirth", value: "Male", label: "Male"},
                                                        {name: "genderAtBirth", value: "Female", label: "Female"}
                                                    ]}
                                                    onChange={handleGenderAtBirth}
                                                    containerClassName="flex-shrink-0 justify-end"
                                                    optionClassName="ml-md"
                                                />
                                            </div>
                                            <div className="flex flex-row items-center w-full gap-md">
                                                <p className="flex-grow">Do you use medication to control Blood
                                                    Pressure?</p>
                                                <RadioGroup
                                                    options={[
                                                        {
                                                            name: "usesBloodPressureMedication",
                                                            value: "false",
                                                            label: "No"
                                                        },
                                                        {
                                                            name: "usesBloodPressureMedication",
                                                            value: "true",
                                                            label: "Yes"
                                                        }
                                                    ]}
                                                    onChange={handleUsesBloodPressureMedication}
                                                    containerClassName="flex-shrink-0 justify-end"
                                                    optionClassName="ml-md"
                                                />
                                            </div>
                                            <div className="flex flex-row items-center w-full gap-md">
                                                <p className="flex-grow">Are you a smoker?</p>
                                                <RadioGroup
                                                    options={[
                                                        {name: "isSmoker", value: "false", label: "No"},
                                                        {name: "isSmoker", value: "true", label: "Yes"}
                                                    ]}
                                                    onChange={handleIsSmoker}
                                                    containerClassName="flex-shrink-0 justify-end"
                                                    optionClassName="ml-md"
                                                />
                                            </div>
                                            <div className="flex flex-row items-center w-full gap-md">
                                                <p className="flex-grow">Diabetes status</p>
                                                <RadioGroup
                                                    options={[
                                                        {name: "diabetesStatus", value: "No", label: "No"},
                                                        {name: "diabetesStatus", value: "Type1", label: "Type 1"},
                                                        {name: "diabetesStatus", value: "Type2", label: "Type 2"}
                                                    ]}
                                                    onChange={handleDiabetesStatus}
                                                    containerClassName="flex-wrap justify-end"
                                                    optionClassName="ml-md"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="space-y-4 md:space-y-0 md:flex md:space-x-8">
                                        <div
                                            className="flex flex-row rounded-xl bg-lime-disclaimer p-4 font-medium justify-items-start gap-md md:basis-4/4">
                                            <p className="py-1">Lime International Ltd control your personal data and need your
                                                consent to share it with Alula Health to provide Health Tracker.
                                                Please read Alula Health's data sharing {" "}
                                                <a
                                                    href={healthTrackerDocs.filter(x => x.name === 'Alula Data Sharing consent').map(x => x.href).at(0)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="font-bold cursor-pointer hover:no-underline visited:text-lime-bg active:text-lime-bg"
                                                >
                                                    Consents
                                                </a>{" "} and {" "}
                                                <a
                                                    href={healthTrackerDocs.filter(x => x.name === 'Alula Privacy Policy').map(x => x.href).at(0)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="font-bold cursor-pointer hover:no-underline visited:text-lime-bg active:text-lime-bg"
                                                >
                                                    Privacy Policy
                                                </a>{" "} for further details.
                                                <span className="py-1">
                                                    <label key="true" className="flex items-center">
                                                        <input
                                                            type="checkbox"
                                                            id="acceptConsentNotice"
                                                            onChange={acceptConsentNotice}
                                                            className="appearance-none"
                                                        />
                                                        <img
                                                            key="true"
                                                            src={acceptedConsentNotice ? Checked : Unchecked}
                                                            alt="accepted"
                                                            className="flex items-start max-w-max max-h-max pt-1"
                                                        />
                                                        <span className="mx-1">
                                                            I have received Alula Health's data sharing consent and Privacy Policy and give my consent to sharing my personal data with Alula Health.
                                                        </span>
                                                    </label>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="flex flex-col basis-3/4">
                                            <div className="align-items-center h-full">
                                                <button
                                                    className="flex rounded-xl bg-lime-orange p-2 font-medium items-center border-none
                                                space-x-4 cursor-pointer justify-center hover:no-underline h-full w-full text-center text-primary-text"
                                                    disabled={
                                                        submitLoading || (submitButtonDisabled() ?? false)
                                                    }
                                                    onClick={undefined}
                                                >
                                                <span className="text-h1 flex flex-row align-middle">
                                                    <strong>SCAN NOW</strong>
                                                </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="hidden sm:flex flex-row sm:flex-col gap-sm sm:gap-xl items-stretch basis-1/4 ml-auto">
                                    <div className="flex flex-col basis-1/3">
                                        <a href={healthTrackerDocs.filter(x => x.name === 'Data Privacy FAQs').map(x => x.href).at(0)}
                                           target="_blank"
                                           rel="noreferrer"
                                           className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                                space-x-4 p-7 sm:p-3 cursor-pointer justify-between hover:no-underline
                                                visited:text-lime-bg active:text-lime-bg font-sans-co w-full h-full">
                                            <div
                                                className="flex flex-col justify-center items-center w-full h-full">
                                            <span
                                                className="text-lime-blue text-[1.25em] sm:text-[1.875em] text-center">
                                                Data Privacy FAQ
                                            </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="flex flex-col gap-sm sm:gap-xl basis-2/3">
                                        <a
                                            href={healthTrackerDocs.filter(x => x.name === 'Service Summary').map(x => x.href).at(0)}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                    space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                            <div
                                                className="flex flex-row sm:flex-col justify-center items-center w-full h-full text-center">
                                                <span className="text-lime-blue text-[1.25em] sm:text-[1.875em]">
                                                    Service Summary
                                                </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                            </div>
                                        </a>
                                        <div
                                            onClick={navigateToDocs}
                                            className="flex rounded-xl bg-quartz font-bold text-lime-bg items-center
                                    space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                            <div
                                                className="flex flex-row sm:flex-col justify-center items-center w-full h-full">
                                <span className="text-lime-blue text-[1.25em] sm:text-[1.875em]">
                                    Documents
                                </span>
                                                <img src={imgInfo} alt="info"
                                                     className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </LoadingSpinner>
                        }
                    </div>
                </>
                :  (layout === 'tablet') ? 
                    (
                        <>
                            {HealthTrackerBanner()}
                            <div className="flex flex-col basis-3/5 gap-xl h-full">
                                {
                                    (!acceptedPrepareForScan) &&
                                    <div className="flex font-medium justify-between mb-md gap-xl basis-3/5">
                                        <div className="flex flex-col basis-3/4 gap-lg">
                                            <div className="text-center pb-2">
                                                <strong className="text-[1.75em] leading-5 text-sherpa-blue">
                                                    Preparing for face scan
                                                </strong>
                                            </div>
                                            <div className="flex flex-col h-full max-h-full">
                                                    <div className="flex gap-md items-center my-2">
                                                        <img
                                                            src={imgWarningExclamation}
                                                            alt="Green circle containing a warning triangle with an exclamation symbol"
                                                            className="w-[3.75em] h-[3.75em]"
                                                        />
                                                        <span className="text-p1 leading-[1.375em]">
                                        <strong>Do not smoke, exercise or consume caffeine or alcohol</strong> for 30 minutes to an hour before the scan
                                    </span>
                                                    </div>
                                                    <div className="flex gap-md items-center my-2">
                                                        <img
                                                            src={imgRecliningOnChair}
                                                            alt="Green circle containing a person reclining on a chair"
                                                            className="w-[3.75em] h-[3.75em]"
                                                        />
                                                        <span className="text-p1 leading-[1.375em]">
                                        <strong>Relax</strong> in a chair for at least <strong>five minutes before</strong> the test
                                    </span>
                                                    </div>
                                                    <div className="flex gap-md items-center my-2">
                                                        <img
                                                            src={imgNoTalkingSign}
                                                            alt="Green circle containing a no talking sign"
                                                            className="w-[3.75em] h-[3.75em]"
                                                        />
                                                        <span className="text-p1 leading-[1.375em]">
                                        <strong>Don’t talk</strong> during the scan
                                    </span>
                                                    </div>
                                                    <div className="flex gap-md items-center my-2">
                                                        <img
                                                            src={imgAlarmClock}
                                                            alt="Green circle containing an alarm clock"
                                                            className="w-[3.75em] h-[3.75em]"
                                                        />
                                                        <span className="text-p1 leading-[1.375em]">
                                        Ideally - take face scan the <strong>same time of the day each month</strong> eg. mid morning or mid afternoon
                                    </span>
                                                    </div>
                                                    <div className="flex gap-md items-center my-2">
                                                        <img
                                                            src={imgNoGlassesSign}
                                                            alt="Green no glasses sign"
                                                            className="w-[3.75em] h-[3.75em]"
                                                        />
                                                        <span className="text-p1 leading-[1.375em]">
                                        If you wear <strong>glasses</strong>, you will need <strong>to</strong> remove for the face scan
                                    </span>
                                                    </div>
                                                <div className="pt-xl">
                                                    <div onClick={acceptPrepareForScan}
                                                         className="flex rounded-xl bg-lime-orange p-2 font-medium items-center
                                                 space-x-4 cursor-pointer justify-center hover:no-underline h-full text-center w-full">
                                                <span className="text-h1 flex flex-row align-middle">
                                                    <strong>NEXT</strong>
                                                </span>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row gap-md items-center flex-wrap">
                                                    <div className="flex flex-col basis-1/3 sm:basis-0 flex-grow">
                                                        <a
                                                            href={healthTrackerDocs.filter(x => x.name === 'Face Scan FAQs').map(x => x.href).at(0)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="flex sm:hidden rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                            space-x-4 p-2 cursor-pointer justify-between hover:no-underline
                                            visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                                                        >
                                                            <div
                                                                className="flex flex-col justify-between items-center w-full h-full gap-sm">
                                                <span className="text-lime-blue leading-5 text-[1.25em] text-center">
                                                    Face Scan FAQ
                                                </span>
                                                                <img
                                                                    src={imgInfo}
                                                                    alt="info"
                                                                    className="w-[1.25em] h-[1.25em]]"
                                                                />
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="flex flex-col basis-1/3 sm:basis-0 flex-grow">
                                                        <a
                                                            onClick={navigateToDocs}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="flex sm:hidden rounded-xl bg-quartz font-bold text-lime-bg items-center
                                            space-x-4 p-2 cursor-pointer justify-between hover:no-underline
                                            visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                                                        >
                                                            <div
                                                                className="flex flex-col justify-between items-center w-full h-full gap-sm">
                                                <span className="text-lime-blue leading-5 text-[1.25em]">
                                                    Documents
                                                </span>
                                                                <img
                                                                    src={imgInfo}
                                                                    alt="info"
                                                                    className="w-[1.25em] h-[1.25em]]"
                                                                />
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="flex flex-col basis-1/3 sm:basis-0 flex-grow">
                                                        <a
                                                            href={healthTrackerDocs.filter(x => x.name === 'Service Summary').map(x => x.href).at(0)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="flex sm:hidden rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                            space-x-4 p-2 cursor-pointer justify-between hover:no-underline
                                            visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                                                        >
                                                            <div
                                                                className="flex flex-col justify-between items-center w-full h-full gap-sm">
                                                <span
                                                    className="text-lime-blue leading-5 text-[1.25em] text-center text-nowrap">
                                                    Service Summary
                                                </span>
                                                                <img
                                                                    src={imgInfo}
                                                                    alt="info"
                                                                    className="w-[1.25em] h-[1.25em]]"
                                                                />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-md">
                                            <div className="flex flex-col basis-1/3">
                                                <a
                                                    href={healthTrackerDocs.filter(x => x.name === 'Face Scan FAQs').map(x => x.href).at(0)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                                        space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                                        visited:text-lime-bg active:text-lime-bg font-sans-co w-full h-full">
                                                    <div
                                                        className="flex flex-col justify-center items-center w-full h-full">
                                                        <span
                                                            className="text-lime-blue text-[1.875em] text-center">
                                                             Face Scan FAQ
                                                        </span>
                                                        <img src={imgInfo} alt="info"
                                                             className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="flex flex-col basis-1/3">
                                                <a
                                                    href={healthTrackerDocs.filter(x => x.name === 'Service Summary').map(x => x.href).at(0)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="flex rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                                    <div
                                                        className="flex flex-col justify-center items-center w-full h-full text-center">
                            <span className="text-lime-blue text-[1.875em]">
                                Service Summary
                            </span>
                                                        <img src={imgInfo} alt="info"
                                                             className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="flex flex-col basis-1/3">
                                                <a
                                                    onClick={navigateToDocs}
                                                    className="flex rounded-xl bg-quartz font-bold text-lime-bg items-center
                                space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                                    <div
                                                        className="flex flex-col justify-center items-center w-full h-full">
                            <span className="text-lime-blue text-[1.875em]">
                                Documents
                            </span>
                                                        <img src={imgInfo} alt="info"
                                                             className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    (acceptedPrepareForScan) &&
                                    <div className="flex font-medium justify-between mb-md gap-xl basis-3/5">
                                        <div className="flex flex-col basis-3/4 gap-lg">
                                            <form onSubmit={handleSubmit(onSubmit)} className="m-1">
                                                <div id="htFormInputs">
                                                    <div className="flex flex-row gap-md items-center my-2">
                                                        <p className="flex-none w-32">Enter your age</p>
                                                        <div className="w-full flex gap-md items-center">
                                                            <input
                                                                className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                                placeholder="Enter age"
                                                                type="number"
                                                                id="age"
                                                                name="age"
                                                                min={HtAgeMin}
                                                                max={HtAgeMax}
                                                                onChange={handleNumberChange}
                                                            />
                                                            {ageError &&
                                                                <span
                                                                    className="text-red-500 text-sm">{ageError}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row gap-md items-center my-2">
                                                        <p className="flex-none w-32">Height (in cm)</p>
                                                        <div className="flex-grow">
                                                            <input
                                                                className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                                placeholder="Height (cm)"
                                                                type="number"
                                                                id="height"
                                                                name="height"
                                                                min={HtHeightMinCm}
                                                                max={HtHeightMaxCm}
                                                                onChange={handleNumberChange}
                                                            />
                                                            {heightError &&
                                                                <span
                                                                    className="text-red-500 text-sm">{heightError}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row gap-md items-center my-2">
                                                        <p className="flex-none w-32">Weight (in kg)</p>
                                                        <div className="flex-grow">
                                                            <input
                                                                className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                                placeholder="Weight (kg)"
                                                                type="number"
                                                                id="weight"
                                                                name="weight"
                                                                min={HtWeightMinKg}
                                                                max={HtWeightMaxKg}
                                                                onChange={handleNumberChange}
                                                            />
                                                            {weightError &&
                                                                <span
                                                                    className="text-red-500 text-sm">{weightError}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="htFormRadios">
                                                    <div className="flex flex-row gap-md items-center my-4">
                                                        <p className="flex-grow">Gender at birth</p>
                                                        <RadioGroup
                                                            options={[
                                                                {
                                                                    name: "genderAtBirth",
                                                                    value: "Male",
                                                                    label: "Male"
                                                                },
                                                                {
                                                                    name: "genderAtBirth",
                                                                    value: "Female",
                                                                    label: "Female"
                                                                }
                                                            ]}
                                                            onChange={handleGenderAtBirth}
                                                            containerClassName="flex-wrap justify-end"
                                                            optionClassName="ml-md m-1"
                                                        />
                                                    </div>
                                                    <div className="flex flex-row gap-md items-center my-4">
                                                        <p className="flex-grow">Are you a smoker?</p>
                                                        <RadioGroup
                                                            options={[
                                                                {name: "isSmoker", value: "false", label: "No  "},
                                                                {name: "isSmoker", value: "true", label: "Yes"}
                                                            ]}
                                                            onChange={handleIsSmoker}
                                                            containerClassName="flex-wrap justify-end"
                                                            optionClassName="ml-md m-1"
                                                        />
                                                    </div>
                                                    <div className="flex flex-row gap-md items-center my-4">
                                                        <p className="basis-1/2 flex-grow">Do you use medication to control
                                                            Blood Pressure?</p>
                                                        <RadioGroup
                                                            options={[
                                                                {
                                                                    name: "usesBloodPressureMedication",
                                                                    value: "false",
                                                                    label: "No "
                                                                },
                                                                {
                                                                    name: "usesBloodPressureMedication",
                                                                    value: "true",
                                                                    label: "Yes"
                                                                }
                                                            ]}
                                                            onChange={handleUsesBloodPressureMedication}
                                                            containerClassName="flex-wrap justify-end"
                                                            optionClassName="ml-md m-1"
                                                        />
                                                    </div>
                                                    <div className="flex flex-row gap-md items-center my-4">
                                                        <p className="flex-grow">Diabetes status</p>
                                                        <RadioGroup
                                                            options={[
                                                                {name: "diabetesStatus", value: "No", label: "No"},
                                                                {
                                                                    name: "diabetesStatus",
                                                                    value: "Type1",
                                                                    label: "Type 1"
                                                                },
                                                                {
                                                                    name: "diabetesStatus",
                                                                    value: "Type2",
                                                                    label: "Type 2"
                                                                }
                                                            ]}
                                                            onChange={handleDiabetesStatus}
                                                            containerClassName="flex-wrap justify-end"
                                                            optionClassName="ml-md m-1"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="flex flex-row rounded-xl bg-lime-disclaimer p-4 font-medium justify-items-start gap-md md:basis-2/3">
                                                    <p className="py-1">Lime International Ltd control your personal
                                                        data and need
                                                        your
                                                        consent
                                                        to share it with Alula Health to provide Health Tracker.
                                                        Please read Alula Health's data sharing {" "}
                                                        <a
                                                            href={healthTrackerDocs.filter(x => x.name === 'Alula Data Sharing consent').map(x => x.href).at(0)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="font-bold cursor-pointer hover:no-underline visited:text-lime-bg active:text-lime-bg"
                                                        >
                                                            Consents
                                                        </a>{" "} and {" "}
                                                        <a
                                                            href={healthTrackerDocs.filter(x => x.name === 'Alula Privacy Policy').map(x => x.href).at(0)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="font-bold cursor-pointer hover:no-underline visited:text-lime-bg active:text-lime-bg"
                                                        >
                                                            Privacy Policy
                                                        </a>{" "} for further details.
                                                        <span className="py-1">
                                                            <label key="true" className="flex items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    id="acceptConsentNotice"
                                                                    onChange={acceptConsentNotice}
                                                                    className="appearance-none"
                                                                />
                                                                <img
                                                                    key="true"
                                                                    src={acceptedConsentNotice ? Checked : Unchecked}
                                                                    alt="accepted"
                                                                    className="flex items-start max-w-max max-h-max"
                                                                />
                                                                <span className="mx-1">
                                                I have received Alula Health's data sharing consent and Privacy Policy and give my consent to sharing my personal data with Alula Health.
                                                </span>
                                                            </label>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="flex justify-center w-full pt-xl">
                                                    <button className="w-full p-2 rounded-xl  space-x-4 bg-lime-orange border-none font-medium text-center text-primary-text
                                    cursor-pointer hover:no-underline max-w-full"
                                                            disabled={submitLoading || (submitButtonDisabled() ?? false)}
                                                            onClick={undefined}
                                                    >
                                    <span className="text-h1 align-middle">
                                        <strong>SCAN NOW</strong>
                                    </span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="flex flex-col gap-md">
                                            <div className="flex flex-col basis-1/3">
                                                <a
                                                    href={healthTrackerDocs.filter(x => x.name === 'Data Privacy FAQs').map(x => x.href).at(0)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                                        space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                                        visited:text-lime-bg active:text-lime-bg font-sans-co w-full h-full">
                                                    <div
                                                        className="flex flex-col justify-center items-center w-full h-full">
                                                        <span
                                                            className="text-lime-blue text-[1.875em] text-center">
                                                            Data Privacy FAQ
                                                        </span>
                                                        <img src={imgInfo} alt="info"
                                                             className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="flex flex-col basis-1/3">
                                                <a
                                                    href={healthTrackerDocs.filter(x => x.name === 'Service Summary').map(x => x.href).at(0)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="flex rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                                    <div
                                                        className="flex flex-col justify-center items-center w-full h-full text-center">
                            <span className="text-lime-blue text-[1.875em]">
                                Service Summary
                            </span>
                                                        <img src={imgInfo} alt="info"
                                                             className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="flex flex-col basis-1/3">
                                                <a
                                                    onClick={navigateToDocs}
                                                    className="flex rounded-xl bg-quartz font-bold text-lime-bg items-center
                                space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                                    <div
                                                        className="flex flex-col justify-center items-center w-full h-full">
                            <span className="text-lime-blue text-[1.875em]">
                                Documents
                            </span>
                                                        <img src={imgInfo} alt="info"
                                                             className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    ) : (
                        <>
                            {HealthTrackerBanner()}
                            <div>
                                {
                                    (!acceptedPrepareForScan) &&
                                    <div className="py-sm">
                                        <div className="text-center pb-2">
                                            <strong className="text-p0 text-sherpa-blue">
                                                Preparing for face scan
                                            </strong>
                                        </div>
                                        <div className="grid h-full gap-sm">
                                            <div className="flex gap-md items-center">
                                                <img
                                                    src={imgWarningExclamation}
                                                    alt="Green circle containing a warning triangle with an exclamation symbol"
                                                    className="w-[2.75em] h-[2.75em]"
                                                />
                                                <span className="text-p2 leading-[1.375em]">
                                    <strong>Do not smoke, exercise or consume caffeine or alcohol</strong> for 30 minutes to an hour before the scan
                                </span>
                                            </div>
                                            <div className="flex gap-md items-center">
                                                <img
                                                    src={imgRecliningOnChair}
                                                    alt="Green circle containing a person reclining on a chair"
                                                    className="w-[2.75em] h-[2.75em]"
                                                />
                                                <span className="text-p2 leading-[1.375em]">
                                    <strong>Relax</strong> in a chair for at least <strong>five minutes before</strong> the test
                                </span>
                                            </div>
                                            <div className="flex gap-md items-center">
                                                <img
                                                    src={imgNoTalkingSign}
                                                    alt="Green circle containing a no talking sign"
                                                    className="w-[2.75em] h-[2.75em]"
                                                />
                                                <span className="text-p2 leading-[1.375em]">
                                    <strong>Don’t talk</strong> during the scan
                                </span>
                                            </div>
                                            <div className="flex gap-md items-center">
                                                <img
                                                    src={imgAlarmClock}
                                                    alt="Green circle containing an alarm clock"
                                                    className="w-[2.75em] h-[2.75em]"
                                                />
                                                <span className="text-p2 leading-[1.375em]">
                                    Ideally - take face scan the <strong>same time of the day each month</strong> eg. mid morning or mid afternoon
                                </span>
                                            </div>
                                            <div className="flex gap-md items-center">
                                                <img
                                                    src={imgNoGlassesSign}
                                                    alt="Green no glasses sign"
                                                    className="w-[2.75em] h-[2.75em]"
                                                />
                                                <span className="text-p2 leading-[1.375em]">
                                    If you wear <strong>glasses</strong>, you will need <strong>to</strong> remove for the face scan
                                </span>
                                            </div>
                                        </div>
                                        <div className="pt-sm">
                                            <div onClick={acceptPrepareForScan}
                                                 className="flex flex-col rounded-xl justify-center bg-lime-orange 
                                    font-medium cursor-pointer hover:no-underline w-full py-sm">
                                                    <span className="text-[1.4em] text-center">
                                                        <strong>NEXT</strong>
                                                    </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex flex-row gap-sm items-center py-sm">
                                                <div className="flex-grow">
                                                    <a
                                                        href={healthTrackerDocs.filter(x => x.name === 'Face Scan FAQs').map(x => x.href).at(0)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                            space-x-4 p-2 cursor-pointer justify-between hover:no-underline
                                            visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                                                    >
                                                        <div
                                                            className="flex flex-col justify-between items-center w-full h-full gap-sm">
                                                <span className="text-lime-blue leading-5 text-[1.25em] text-center">
                                                    Face Scan FAQ
                                                </span>
                                                            <img
                                                                src={imgInfo}
                                                                alt="info"
                                                                className="w-[1.25em] h-[1.25em]]"
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="flex-grow">
                                                    <a
                                                        onClick={navigateToDocs}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="flex rounded-xl bg-quartz font-bold text-lime-bg items-center
                                            space-x-4 p-2 cursor-pointer justify-between hover:no-underline
                                            visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                                                    >
                                                        <div
                                                            className="flex flex-col justify-between items-center w-full h-full gap-sm">
                                                <span className="text-lime-blue leading-5 text-[1.25em]">
                                                    Documents
                                                </span>
                                                            <img
                                                                src={imgInfo}
                                                                alt="info"
                                                                className="w-[1.25em] h-[1.25em]]"
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="flex flex-col flex-grow">
                                                <a
                                                    href={healthTrackerDocs.filter(x => x.name === 'Service Summary').map(x => x.href).at(0)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="flex rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                            space-x-4 p-2 cursor-pointer justify-between hover:no-underline
                                            visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                                                >
                                                    <div
                                                        className="flex flex-col justify-between items-center w-full h-full gap-sm">
                                                <span
                                                    className="text-lime-blue leading-5 text-[1.25em] text-center text-nowrap">
                                                    Service Summary
                                                </span>
                                                        <img
                                                            src={imgInfo}
                                                            alt="info"
                                                            className="w-[1.25em] h-[1.25em]]"
                                                        />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    (acceptedPrepareForScan) &&
                                    <div className="py-sm">
                                        <form onSubmit={handleSubmit(onSubmit)} className="m-1">
                                            <div id="htFormInputs">
                                                <div className="flex flex-row gap-md items-center my-2">
                                                    <p className="flex-none w-32">Enter your age</p>
                                                    <div className="w-full flex gap-md items-center">
                                                        <input
                                                            className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                            placeholder="Enter age"
                                                            type="number"
                                                            id="age"
                                                            name="age"
                                                            min={HtAgeMin}
                                                            max={HtAgeMax}
                                                            onChange={handleNumberChange}
                                                        />
                                                        {ageError &&
                                                            <span
                                                                className="text-red-500 text-sm">{ageError}</span>}
                                                    </div>
                                                </div>
                                                <div className="flex flex-row gap-md items-center my-2">
                                                    <p className="flex-none w-32">Height (in cm)</p>
                                                    <div className="flex-grow">
                                                        <input
                                                            className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                            placeholder="Height (cm)"
                                                            type="number"
                                                            id="height"
                                                            name="height"
                                                            min={HtHeightMinCm}
                                                            max={HtHeightMaxCm}
                                                            onChange={handleNumberChange}
                                                        />
                                                        {heightError &&
                                                            <span
                                                                className="text-red-500 text-sm">{heightError}</span>}
                                                    </div>
                                                </div>
                                                <div className="flex flex-row gap-md items-center my-2">
                                                    <p className="flex-none w-32">Weight (in kg)</p>
                                                    <div className="flex-grow">
                                                        <input
                                                            className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                            placeholder="Weight (kg)"
                                                            type="number"
                                                            id="weight"
                                                            name="weight"
                                                            min={HtWeightMinKg}
                                                            max={HtWeightMaxKg}
                                                            onChange={handleNumberChange}
                                                        />
                                                        {weightError &&
                                                            <span
                                                                className="text-red-500 text-sm">{weightError}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="htFormRadios">
                                                <div className="flex flex-row gap-md items-center my-4">
                                                    <p className="flex-grow">Gender at birth</p>
                                                    <RadioGroup
                                                        options={[
                                                            {
                                                                name: "genderAtBirth",
                                                                value: "Male",
                                                                label: "Male"
                                                            },
                                                            {
                                                                name: "genderAtBirth",
                                                                value: "Female",
                                                                label: "Female"
                                                            }
                                                        ]}
                                                        onChange={handleGenderAtBirth}
                                                        containerClassName="flex-wrap justify-between"
                                                        optionClassName="m-1"
                                                    />
                                                </div>
                                                <div className="flex flex-row gap-md items-center my-4">
                                                    <p className="flex-grow">Are you a smoker?</p>
                                                    <RadioGroup
                                                        options={[
                                                            {name: "isSmoker", value: "false", label: "No  "},
                                                            {name: "isSmoker", value: "true", label: "Yes"}
                                                        ]}
                                                        onChange={handleIsSmoker}
                                                        containerClassName="flex-wrap justify-between"
                                                        optionClassName="m-1"
                                                    />
                                                </div>
                                                <div className="flex flex-row gap-md items-center my-4">
                                                    <p className="basis-1/2 flex-grow">Do you use medication to
                                                        control
                                                        Blood Pressure?</p>
                                                    <RadioGroup
                                                        options={[
                                                            {
                                                                name: "usesBloodPressureMedication",
                                                                value: "false",
                                                                label: "No "
                                                            },
                                                            {
                                                                name: "usesBloodPressureMedication",
                                                                value: "true",
                                                                label: "Yes"
                                                            }
                                                        ]}
                                                        onChange={handleUsesBloodPressureMedication}
                                                        containerClassName="flex-wrap justify-between"
                                                        optionClassName="m-1"
                                                    />
                                                </div>
                                                <div className="flex flex-row gap-md items-center my-4">
                                                    <p className="flex-grow basis-1/5">Diabetes status</p>
                                                    <RadioGroup
                                                        options={[
                                                            {name: "diabetesStatus", value: "No", label: "No"},
                                                            {
                                                                name: "diabetesStatus",
                                                                value: "Type1",
                                                                label: "Type 1"
                                                            },
                                                            {
                                                                name: "diabetesStatus",
                                                                value: "Type2",
                                                                label: "Type 2"
                                                            }
                                                        ]}
                                                        onChange={handleDiabetesStatus}
                                                        containerClassName="flex-wrap justify-between"
                                                        optionClassName="m-1"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="flex flex-row rounded-xl bg-lime-disclaimer p-4 font-medium justify-items-start">
                                                <p className="py-1">Lime International Ltd control your personal
                                                    data and need
                                                    your
                                                    consent
                                                    to share it with Alula Health to provide Health Tracker.
                                                    Please read Alula Health's data sharing {" "}
                                                    <a
                                                        href={healthTrackerDocs.filter(x => x.name === 'Alula Data Sharing consent').map(x => x.href).at(0)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="font-bold cursor-pointer hover:no-underline visited:text-lime-bg active:text-lime-bg"
                                                    >
                                                        Consents
                                                    </a>{" "} and {" "}
                                                    <a
                                                        href={healthTrackerDocs.filter(x => x.name === 'Alula Privacy Policy').map(x => x.href).at(0)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="font-bold cursor-pointer hover:no-underline visited:text-lime-bg active:text-lime-bg"
                                                    >
                                                        Privacy Policy
                                                    </a>{" "} for further details.
                                                    <span className="py-1">
                                                            <label key="true" className="flex items-center">
                                                                <input
                                                                    type="checkbox"
                                                                    id="acceptConsentNotice"
                                                                    onChange={acceptConsentNotice}
                                                                    className="appearance-none"
                                                                />
                                                                <img
                                                                    key="true"
                                                                    src={acceptedConsentNotice ? Checked : Unchecked}
                                                                    alt="accepted"
                                                                    className="flex items-start max-w-max max-h-max"
                                                                />
                                                                <span className="mx-1">
                                                I have received Alula Health's data sharing consent and Privacy Policy and give my consent to sharing my personal data with Alula Health.
                                                </span>
                                                            </label>
                                                        </span>
                                                </p>
                                            </div>
                                            <div className="py-sm">
                                                <button className="p-2 rounded-xl space-x-4 bg-lime-orange border-none font-medium text-center text-primary-text
                                    cursor-pointer hover:no-underline max-w-full w-full"
                                                        disabled={submitLoading || (submitButtonDisabled() ?? false)}
                                                        onClick={undefined}
                                                >
                                                    <span className="text-h1 align-middle">
                                                        <strong>SCAN NOW</strong>
                                                    </span>
                                                </button>
                                            </div>
                                        </form>
                                        <div>
                                            <div className="flex flex-row gap-sm items-center pb-sm">
                                                <div className="flex-grow">
                                                    <a
                                                        href={healthTrackerDocs.filter(x => x.name === 'Face Scan FAQs').map(x => x.href).at(0)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                            space-x-4 p-2 cursor-pointer justify-between hover:no-underline
                                            visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                                                    >
                                                        <div
                                                            className="flex flex-col justify-between items-center w-full h-full gap-sm">
                                                <span className="text-lime-blue leading-5 text-[1.25em] text-center">
                                                    Face Scan FAQ
                                                </span>
                                                            <img
                                                                src={imgInfo}
                                                                alt="info"
                                                                className="w-[1.25em] h-[1.25em]]"
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="flex-grow">
                                                    <a
                                                        onClick={navigateToDocs}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="flex rounded-xl bg-quartz font-bold text-lime-bg items-center
                                            space-x-4 p-2 cursor-pointer justify-between hover:no-underline
                                            visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                                                    >
                                                        <div
                                                            className="flex flex-col justify-between items-center w-full h-full gap-sm">
                                                <span className="text-lime-blue leading-5 text-[1.25em]">
                                                    Documents
                                                </span>
                                                            <img
                                                                src={imgInfo}
                                                                alt="info"
                                                                className="w-[1.25em] h-[1.25em]]"
                                                            />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="flex flex-col flex-grow">
                                                <a
                                                    href={healthTrackerDocs.filter(x => x.name === 'Service Summary').map(x => x.href).at(0)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="flex rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                            space-x-4 p-2 cursor-pointer justify-between hover:no-underline
                                            visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                                                >
                                                    <div
                                                        className="flex flex-col justify-between items-center w-full h-full gap-sm">
                                                <span
                                                    className="text-lime-blue leading-5 text-[1.25em] text-center text-nowrap">
                                                    Service Summary
                                                </span>
                                                        <img
                                                            src={imgInfo}
                                                            alt="info"
                                                            className="w-[1.25em] h-[1.25em]]"
                                                        />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    )
            }
            <div className="flex flex-col">
                <div
                    className="flex lg:hidden flex-row rounded-xl bg-lime-fg items-center justify-between gap-md max-h-[3em] align-bottom">
                    <div className={`px-[0.1em] py-[0.4em] sm:basis-1/4 mx-sm p-sm basis-1/2`}>
                        <div className={`relative`}>
                            <h3
                                className={`text-sherpa-blue bg-moss-green m-0 py-[0.4em] pl-[2em] font-sans-co font-bold text-[0.9em] rounded-xl  items-center`}
                            >
                            <span
                                className={`w-[2.4em] h-[2.4em] -top-[0.2em] -left-[0.4em] rounded-full bg-moss-green absolute flex items-center justify-center p-sm`}
                            >
                                <span
                                    className={"z-10 w-[1.4em] h-[1.4em] rounded-full absolute top-[0.5em] left-[0.5em] bg-lime-green"}
                                ></span>
                                <img
                                    src={score}
                                    alt="Health Tracker logo"
                                    className="w-[1.4em] h-[1.4em] z-20"
                                />
                            </span>{" "}
                                Health Tracker
                            </h3>
                        </div>
                    </div>
                    <div className="flex float-end items-center mx-sm p-sm">
                        <span className="text-[0.54em] mr-2">Powered by</span>
                        <img
                            src={alulaLogo}
                            alt={`Alula Technologies logo`}
                            className={`h-[1.6em]`}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;