import React from "react";
import { ProductIds } from "../../models/ProductEnums";
import useProducts from "../../utils/useProducts";
import ProductSection from "./ProductSection";
import {healthTrackerDocs, memberDocs, fastAnswersDocs, cancerNavigatorDocs} from "./docs";

function Documents() {

    const { products, loading } = useProducts();

    const hasHealthTracker = products.some(product => product.productUuid === ProductIds.HealthTracker);
    const hasFastAnswers = products.some(product => product.productUuid === ProductIds.HealthEssentials);
    const hasCancerNavigator = products.some(product => product.productUuid === ProductIds.CancerNavigator);

  return (
      <div id="pageDocuments" className="w-full">
        <h2 className="lg:text-h1">Your Documents</h2>
        <ProductSection name="Lime's Member Documents" documents={memberDocs} key="general"/>
          {(!loading && hasFastAnswers) && (
            <ProductSection name="Health Essentials" documents={fastAnswersDocs} key="essentials"/>
          )}
          {(!loading && hasHealthTracker) && (
            <ProductSection name="Health Tracker" documents={healthTrackerDocs} key="healthTracker"/>
          )}
          {(!loading && hasCancerNavigator) && (
            <ProductSection name="Cancer Navigator" documents={cancerNavigatorDocs} key="cancerNavigator"/>
          )}
      </div>
  );
}

export default Documents;