import React, {useState} from 'react';
import LogoHeart from "../../images/lime-heart.svg";
import LogoDark from "../../images/lime-dark.svg";
import NavHam from "../../images/nav-ham.svg";
import NavClose from "../../images/x-close.svg";
import DashboardDark from "../../images/dashboard-dark.svg";
import DashboardLight from "../../images/dashboard-light.svg";
import AppointmentsDark from "../../images/appointments-dark.svg";
import AppointmentsLight from "../../images/appointments-light.svg";
import HeartPlusDark from "../../images/heart-plus-dark.svg";
import HeartPlusLight from "../../images/heart-plus-light.svg";
import DocsDark from "../../images/docs-dark.svg";
import DocsLight from "../../images/docs-light.svg";
import HelpDark from "../../images/help-dark.svg";
import HelpLight from "../../images/help-light.svg";
import ProfileDark from "../../images/profile-dark.svg";
import ProfileLight from "../../images/profile-light.svg";
import Logout from "../../images/logout.svg";
import CheckHeart from "../../images/check-heart.svg";
import NavBarItem from "./NavBarItem";
import {useAuth} from "react-oidc-context";
import NavBarAction from "./NavBarAction";
import {useLocation} from 'react-router-dom'
import {claimValueContains, getClaimValue} from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import UserType from "../../models/userType";
import OrganisationUserType from "../../models/organisationUserType";
import {User} from "oidc-client-ts";
import {composeUrl, limeApiGet} from "../../utils/api";
import useProducts from "../../utils/useProducts";
import {ProductIds} from "../../models/ProductEnums";
import {getFeatureFlag} from "../../utils/featureFlag";

function MobileNavBar() {
    const auth = useAuth();
    const location = useLocation();
    const currentNav = location.pathname.substring(1).split('/')[0];
    const [displayNav, setDisplayNav] = useState(false);
    const [canSwitchSpace, setCanSwitchSpace] = useState<boolean>(false);
    const [organisationPortalUrl, setOrganisationPortalUrl] = useState("#");
    const {products, loading} = useProducts();

    const hasHealthTracker = products.some(product => product.productUuid === ProductIds.HealthTracker);
    const hasFastAnswers = products.some(product => product.productUuid === ProductIds.HealthEssentials);
    const hasCancerNavigator = products.some(product => product.productUuid === ProductIds.CancerNavigator);

    React.useEffect(() => {
        setDisplayNav(false);
    }, [location]);

    React.useEffect(() => {
        let isOrganisationUser = (user: User | null | undefined) => getClaimValue(user, LimeClaimTypes.UserType) === UserType.Organisation && getClaimValue(user, LimeClaimTypes.OrganisationUuid);
        let isOrganisationAdmin = (user: User | null | undefined) => claimValueContains(user, LimeClaimTypes.OrganisationUserType, OrganisationUserType.Admin);
        let isOrganisationMember = (user: User | null | undefined) => claimValueContains(user, LimeClaimTypes.OrganisationUserType, OrganisationUserType.Member);

        if (isOrganisationUser(auth.user) && isOrganisationAdmin(auth.user) && isOrganisationMember(auth.user)) {
            setCanSwitchSpace(true);
        }
    }, [auth, auth.user]);

    React.useEffect(() => {
        const fetchData = async () => {
            const configUrl = composeUrl('configuration');
            const response = await fetch(configUrl);
            const config = await response.json();
            setOrganisationPortalUrl(config.organisation);
        }
        void fetchData();
    }, []);

    const formatNavTitle = (nav: string) => {
        return nav
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/\b\w/g, char => char.toUpperCase());
    }

    return (
        <nav id="mobileNavbar" className="bg-pure-white px-md py-sm lg:hidden lg:row-start-1 lg:row-span-2 border-b-[1px] border-b-light-grey
                       lg:border-0">
            <div className="flex justify-between items-center lg:flex-col lg:items-start lg:p-lg lg:h-full">
                <img src={LogoHeart} alt="lime logo" className="h-[2rem] lg:hidden" />
                <img src={LogoDark} alt="lime logo" className="hidden lg:block lg:flex-shrink lg:w-full lg:mb-lg" />
                <button className="px-sm p-xs w-fit rounded-lg lg:hidden font-sans-co">
                    {formatNavTitle(currentNav)}
                </button>
                <input type="checkbox" id="nav-state" className="hidden peer" checked={displayNav}
                       onChange={e => setDisplayNav(e.target.checked)} />
                <label
                    className="hidden lg:hidden bg-dark-grey/[.5] fixed top-0 bottom-0 left-0 w-full h-full lg:w-0 lg:h-0 z-30 peer-checked:block"
                    htmlFor="nav-state"></label>
                <label htmlFor="nav-state" className="cursor-pointer lg:hidden">
                    <img src={NavHam} alt="navigation menu" />
                </label>

                <div className="hidden fixed bg-pure-white z-40 top-0 left-0 h-full p-lg peer-checked:block
        lg:relative lg:flex lg:flex-col lg:p-0 lg:h-full">
                    <label htmlFor="nav-state" className="cursor-pointer lg:hidden inline-block mb-sm">
                        <img src={NavClose} alt="close navigation" className="h-[2rem]" />
                    </label>

                    <ul className="mb-2xl lg:mb-0 lg:flex-grow">
                        <NavBarItem route={"dashboard"} title="Dashboard" icon={DashboardDark}
                                    activeIcon={DashboardLight} />
                        {(!loading && hasHealthTracker) && (
                            <NavBarItem route={"healthTracker"} title="Health Tracker" icon={CheckHeart}
                                        activeIcon={LogoHeart}/>
                        )}
                        {(!loading && hasFastAnswers) && (
                            <NavBarItem route={"healthEssentials"} title="Health Essentials" icon={AppointmentsDark}
                                        activeIcon={AppointmentsLight}/>
                        )}
                        {(!loading && hasCancerNavigator) && (
                            <NavBarItem route={"cancer"} title="Cancer" icon={HeartPlusDark}
                                        activeIcon={HeartPlusLight} />
                        )}
                        <NavBarItem route={"documents"} title="Documents" icon={DocsDark} activeIcon={DocsLight} />
                        <NavBarItem route={"help"} title="Help" icon={HelpDark} activeIcon={HelpLight} />
                    </ul>

                    <ul className="lg:flex-shrink">
                        {
                            canSwitchSpace &&
                            <li>
                                <a href={organisationPortalUrl} className="flex w-fit lg:w-full block px-sm py-xs lg:py-sm mb-md font-bold font-sans-co text-primary-text
                                     visited:text-primary-text active:text-primary-text bg-pure-white hover:no-underline hover:bg-super-light-grey
                                     rounded-md lg:rounded-lg border-solid border-4 border-lime-green">
                                    <img src={CheckHeart} alt="Logout"
                                         className="hidden lg:block lg:mr-md lg:h-md lg:mt-1" /> Switch Space
                                </a>
                            </li>
                        }
                        <NavBarItem route={"profile"} title="Profile" icon={ProfileDark} activeIcon={ProfileLight} />
                        <NavBarAction onClick={auth.signoutRedirect}>
                            <img src={Logout} alt="Logout" className="hidden lg:block lg:mr-md lg:h-md lg:mt-1" /> Logout
                        </NavBarAction>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default MobileNavBar;