import React from 'react';
import {Nudge, Measurement} from './HealthTrackerConstants';
import HealthScoreOverallGauge from './HealthScoreOverallGuage';
import HealthScoreNudges from './HealthScoreNudges';
import HealthScoreBiometricsLinks from './HealthScoreBiometricsLinks';
import HealthScoreBiometrics from './HealthScoreBiometrics';
import HealthEssentialsCta from './HealthEssentialsCta';

interface HealthTrackerMainResultsProps {
    healthScoreValue: number;
    benchmark: string;
    calculationDate: Date;
    nudges: Nudge[];
    measurements: Measurement[];
}

const HealthTrackerMainResults: React.FC<HealthTrackerMainResultsProps> = ({
                                                                               healthScoreValue,
                                                                               benchmark,
                                                                               calculationDate,
                                                                               nudges,
                                                                               measurements,
                                                                           }) => {
    return (
        <>
            <div className="flex flex-col lg:flex-row gap-sm">
                <div id="healthScoreOverallGauge" className="mb-lg w-full lg:w-[40%]">
                    <HealthScoreOverallGauge
                        value={healthScoreValue}
                        benchmark={benchmark}
                        calculationDate={calculationDate}
                    />
                </div>
                <div className="mb-lg w-full lg:w-[60%]">
                    <div className="flex flex-col lg:flex-row gap-sm h-full">
                        <div id="healthScoreBiometricsLinks" className="w-full lg:w-[60%] h-full">
                            <HealthScoreBiometricsLinks measurements={measurements}/>
                        </div>
                        <div id="healthScoreNudges" className="w-full lg:w-[40%] h-full">
                            <HealthScoreNudges nudges={nudges}/>
                        </div>
                    </div>
                </div>
            </div>
            <div id="healthScoreBiometrics" className="mb-xl w-full">
                <HealthScoreBiometrics measurements={measurements}/>
            </div>
            <div id="healthEssentialsCta" className="mb-xl flex flex-row place-content-center">
                <HealthEssentialsCta/>
            </div>
        </>
    );
};

export default HealthTrackerMainResults;