import { User } from "oidc-client-ts";

export function hasRoleClaim(user: User | null | undefined, roleClaim: string): boolean {
    if (user) {
        let roleClaims = user.profile.role as string[];        
        if (roleClaims) {
            return roleClaims.indexOf(roleClaim) > -1;    
        }        
    }

    return false;
}

export function getClaimValue(user: User | null | undefined, type: string): string | undefined {
    if (user) {
        let claimValue = user.profile[type] as string;
        if (claimValue) {
            return claimValue;
        }
    }
    
    return undefined;
}

export function claimValueContains(user: User | null | undefined, type: string, value: string): boolean {
    if (user) {
        let claimValueArray = user.profile[type] as string[];
        if (claimValueArray) {
            return claimValueArray.indexOf(value) !== -1;            
        }
    }

    return false;
}

export function hasScope(user: User | null | undefined, scope: string): boolean {
    if (user) {
        return user.scopes.indexOf(scope) !== -1;
    }

    return false;
}