import React from "react";
import imgMobileFaceScan from "../../images/health-tracker/face-scan-icon.svg"
import imgOnCall from "../../images/dashboard/oncall.png";
import imgScoreDial from "../../images/dashboard/tracker_icon.png";
import {useViewport} from "react-viewport-hooks";

function HealthTrackerBanner() {
    const {vw} = useViewport();
    let layout = 'desktop';
    if (vw <= 1024 && vw >= 768) {
        layout = 'tablet';
    } else if (vw < 768) {
        layout = 'mobile';
    }
    return (
        <>
            {
                layout === 'desktop' ? (
                    <div className="pt-sm sm:pt-lg mb-xl">
                        <div
                            className="flex p-md sm:py-2 sm:px-7 gap-md sm:gap-lg rounded-xl justify-between items-stretch bg-lime-fg w-full mb-lg">
                            <div className="flex flex-col-2 gap-lg">
                                <div className="flex flex-col gap-md order-1 sm:order-2 justify-start">
                                    <span className="text-sherpa-blue text-[1.125em] sm:text-p0 sm:font-medium py-lg my-lg ">
                                    Monitor your health, take a <strong>monthly</strong> scan and see your current scores.
                                    </span>
                                </div>
                                <div className="flex flex-col gap-md order-2 sm:order-1 justify-center">
                                    <img src={imgMobileFaceScan}
                                         alt="Mobile phone camera doing a face scan"
                                         className="h-[5em] sm:w-[9.5em] sm:h-[11.6em] my-2"/>
                                </div>
                            </div>
                            <div className="flex lg:flex-col justify-center order-3">
                                <div className="flex place-content-end my-sm">
                                    <div className={`flex sm:ml-[2em] py-[0.4em] my-sm`}>
                                        <div className={`relative`}>
                                            <h3
                                                className={`text-sherpa-blue bg-moss-green m-0 py-[0.4em] pl-[2.1em] pr-[1.3em] font-sans-co font-bold text-[1em] sm:text-[2.5em] rounded-2xl items-center`}>
                                                <span
                                                    className={`w-[2.8em] h-[2.8em] -top-[0.4em] -left-[0.8em] rounded-full bg-moss-green absolute flex items-center justify-center`}>
                                                    <img src={imgScoreDial} alt="Health Tracker logo" className="w-[1.8em] h-[1.8em] z-20"/>
                                                </span> Health Tracker
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className={`flex place-content-end -mt-sm`}>
                                    <img src={imgOnCall}
                                         alt="OnCall"
                                         className=" h-[4.375em] w-[12.125em]"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                   :
                    (   layout === 'tablet' ? (
                            <div className="pt-sm sm:pt-lg mb-xl">
                                <div
                                    className="flex gap-md rounded-xl bg-lime-fg mb-lg p-md">
                                    <div className="flex flex-col order-1 justify-center">
                                        <img src={imgMobileFaceScan}
                                             alt="Mobile phone camera doing a face scan"
                                             className="h-[5em] sm:w-[9.5em] sm:h-[11.6em]"/>
                                    </div>
                                    <div className="flex flex-col order-2">
                                        <div className="flex flex-row justify-between">
                                            <div className={`relative mt-md mx-md`}>
                                                    <h3
                                                        className={`text-sherpa-blue bg-moss-green py-[0.4em] pl-[2.1em] font-sans-co font-bold rounded-2xl px-md`}>
                                                        <span
                                                            className={`w-[2.8em] h-[2.8em] -top-[0.4em] -left-[0.8em] rounded-full bg-moss-green absolute flex items-center justify-center`}>
                                                            <img src={imgScoreDial} alt="Health Tracker Logo"
                                                                 className="w-[1.8em] h-[1.8em] z-10"/>
                                                        </span> Health Tracker
                                                    </h3>
                                                </div>
                                            <div>
                                                <img src={imgOnCall}
                                                     alt="OnCall"
                                                     className=" h-[4.375em] w-[12.125em]"/>
                                            </div>
                                        </div>
                                        <div className="mt-lg">
                                             <span
                                                 className="text-sherpa-blue lg:text-[2em] text-[1.5em] font-medium">
                                            Monitor your health, take a <strong>monthly</strong> scan and see your current scores.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ) : (
                            <div className="flex rounded-xl bg-lime-fg p-sm gap-xl">
                                <div className="flex flex-col basis-2/5 justify-center">
                                    <img src={imgMobileFaceScan}
                                         alt="Mobile phone camera doing a face scan"
                                         className="w-[4.5em] h-[6.5em]"/>
                                </div>
                                <div className="flex flex-col justify-center">
                                    <span
                                        className="text-sherpa-blue font-medium text-p1">
                                        Monitor your health, take a <strong>monthly</strong> scan and see your current scores.
                                    </span>
                                </div>
                            </div>
                        )
                    )
            }
        </>
    )
}

export default HealthTrackerBanner;