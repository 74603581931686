import React from "react";
import {useNavigate} from 'react-router-dom';

interface CashClaimConfirmationProps {
    claimReference: string;
}

function CashClaimConfirmation({ claimReference }: CashClaimConfirmationProps) {
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate('/cancer');
    };

    return (
        <div>
            <div className="mb-lg">
                <h3>Claim Reference: {claimReference}</h3>
            </div>
            <div className="mb-lg">
                <p><big>Thank you, your claim has been successfully created</big></p>
            </div>
            <div className="mb-lg">
                <p>We’ll notify you once we’ve reviewed the information you have provided.</p>
            </div>
            <div className="mb-lg">
                <button className="mx-sm" onClick={handleContinue}>Continue</button>
            </div>
        </div>
    );
}

export default CashClaimConfirmation;