import React, { FunctionComponent, useState } from "react";

interface RadioOption {
    name: string,
    value: string,
    label: string
}

interface RadioGroupProps {
    options: RadioOption[];
    onChange: (selectedValue: string) => void;
    containerClassName?: string;
    optionClassName?: string;
    fillColor?: string;
}

const CheckedRadio: React.FC<{ fillColor: string }> = ({ fillColor }) => (
    <svg width="26" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="15" fill="white" stroke="#252525" strokeWidth="2"/>
        <circle cx="16" cy="16" r="8" fill={fillColor}/>
    </svg>
);

const UncheckedRadio = () => (
    <svg width="26" height="26" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="15" fill="white" stroke="#252525" strokeWidth="2"/>
    </svg>
);

const RadioGroup: FunctionComponent<RadioGroupProps> = ({
    options,
    onChange,
    containerClassName = "",
    optionClassName = "",
    fillColor = "#252525"
}) => {
    const [selectedValue, setSelectedValue] = useState<string>();

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        setSelectedValue(newValue);
        onChange(newValue);
    };
    
    return (
        <div className={`flex ${containerClassName}`}>
            {options.map((option) => (
                <label key={option.value}
                       className={`text-p1 flex items-center whitespace-nowrap ${optionClassName}`}>
                    <input
                        type="radio"
                        id={option.name + option.value}
                        name={option.name}
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={handleRadioChange}
                        className="hidden"
                    />
                    <span className="mr-2">
                        {selectedValue === option.value ? <CheckedRadio fillColor={fillColor} /> : <UncheckedRadio/>}
                    </span>
                    {option.label}
                </label>
            ))}
        </div>
    );
};

export default RadioGroup;