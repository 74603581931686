import React from "react";
import {useNavigate} from 'react-router-dom';

function CashClaimUnavailable() {
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate('/cancer');
    };

    return (
        <div>
            <div className="mb-lg">
                <p>
                    <strong>
                        Unfortunately we need to re-evaluate which services you’re
                        eligible for as the information we have is
                        either out of date or invalid.
                    </strong>
                </p>
            </div>
            <div className="mb-lg">
                <p>
                    You can raise another cash claim provided you’re still eligible.
                </p>
            </div>
            <div className="my-lg">
                <button className="mx-sm" onClick={handleContinue}>Continue</button>
            </div>
        </div>
    );
}

export default CashClaimUnavailable;