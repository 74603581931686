export default class Eligibility {
    createdOn: string;
    diagnosisDate: string;
    answers: { [key: string]: boolean };
    eligibleServices: string[];

    constructor(createdOn: string, diagnosisDate: string, answers: { [key: string]: boolean }, eligibleServices: string[]) {
        this.createdOn = createdOn;
        this.diagnosisDate = diagnosisDate;
        this.answers = answers;
        this.eligibleServices = eligibleServices;
    }
}