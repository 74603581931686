import React from "react";
import Spinner from "../../../images/spinner-dark.svg";

interface Props {
    loading: boolean;
    children: React.ReactNode;
}

function LoadingSpinner(props: Props) {
    return (
      props.loading ?
        <div className="relative h-min-4xl">
          <div className="z-20 opacity-10">
            { props.children }
          </div>
          <div className="absolute left-0 top-0 h-full w-full z-50 py-xl">
            <div className="flex items-center justify-center h-full">
              <img src={Spinner} alt="spinner" className="w-2xl h-2xl animate-spin" />
            </div>
          </div>
        </div> :
        <>
          { props.children }
        </>
    );
}

export default LoadingSpinner;