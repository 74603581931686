import React, { useState } from "react";
import WelcomeHeader from "./WelcomeHeader";
import WelcomeIntro from "./WelcomeIntro";
import WelcomeGetStarted from "./WelcomeGetStarted";
import Footer from "../Footer/Footer";

export interface WelcomeJourneyProps {
    onAcceptTerms: () => Promise<boolean>
}

function WelcomeJourney(props: WelcomeJourneyProps) {
  const [started, setGetStarted] = useState(false);
  const getStarted = () => {
    setGetStarted(true);
  }

  return (
    <div className="container mx-auto grid grid-cols-4 sm:grid-cols-12 grid-rows-auto auto-rows-min sm:h-full bg-dark-grey
      sm:grid-rows-[12rem_1fr_1fr]">
      <WelcomeHeader />
      { started ? <WelcomeGetStarted onAcceptTerms={props.onAcceptTerms} /> : <WelcomeIntro onGetStarted={getStarted} /> }
      <footer className="col-span-4 sm:col-span-12 self-stretch">
        <Footer />
      </footer>
    </div>
  );
}

export default WelcomeJourney;