import React from 'react';

interface Props {
  href: string;
  newTab?: boolean;
  children: React.ReactNode;
}

function FooterLink(props: Props) {
  return (
    <a href={props.href} target="_blank" className="text-p2 inline-block text-pure-white decoration-0 hover:underline
        active:text-pure-white visited:text-pure-white m-1">
      {props.children}
    </a>
  );
}

export default FooterLink;