import React, {useEffect, useState} from 'react';
import {useAuth} from "react-oidc-context";
import OptOutModal from "../OptOut/OptOutModal";
import {composeUrl} from "../../utils/api";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";

const OptOutCard: React.FC = () => {
    const auth = useAuth();
    const [isOptOutModalOpen, setIsOptOutModalOpen] = useState(false);
    const [isEligibleForOptOut, setIsEligibleForOptOut] = useState(false);
    const [memberStartDate, setMemberStartDate] = useState(new Date());
    const [optOutLoading, setOptOutLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setOptOutLoading(true);
            try {
                const response = await fetch(composeUrl('profile'), {headers: [['Authorization', `Bearer ${auth.user?.access_token}`]]});
                const member = await response.json();
                setIsEligibleForOptOut(member.isEligibleForOptOut);
                setMemberStartDate(new Date(member.startDate));
            } catch (error) {
                console.error("Failed to fetch member details:", error);
            } finally {
                setOptOutLoading(false);
            }
        };

        if (auth.user) {
            fetchData();
        }
    }, [auth.user]);

    const openOptOutModal = () => {
        setIsOptOutModalOpen(true);
    };

    if (!isEligibleForOptOut) {
        return null;
    }

    return (
        <LoadingSpinner loading={optOutLoading}>
            <div className="opt-out-card bg-pure-white p-md rounded-lg my-md grid gap-md lg:my-xl lg:p-sm">
                <div>
                    <h4 className="font-bold mb-md lg:mb-auto">We're only getting started</h4>
                    <p>If you would rather opt out of using Lime altogether, let us know here</p>
                </div>
                <button
                    className="w-fit block self-center px-xl py-sm font-bold text-primary-text visited:text-primary-text active:text-primary-text bg-button-background-blue border-background-blue rounded-md font-sans-co whitespace-nowrap"
                    onClick={openOptOutModal}>
                    Opt-Out
                </button>
                <OptOutModal isOpen={isOptOutModalOpen} setIsOpen={setIsOptOutModalOpen} startDate={memberStartDate}/>
            </div>
        </LoadingSpinner>
    );
};

export default OptOutCard;