import React from 'react';
import {useNavigate} from "react-router-dom";
import DashboardTile, {TileType} from "./DashboardTile";
import {useAuth} from "react-oidc-context";
import imgDocuments from "../../images/dashboard/documents.png";
import imgMember from "../../images/dashboard/member.png";
import imgArrows from "../../images/dashboard/arrows.png";
import imgExit from "../../images/dashboard/exit.png";
import imgTrustpilot from "../../images/dashboard/trustpilot.png";
import imgGuide from "../../images/dashboard/guidance.png";

const DashboardOtherTiles: React.FC = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    const navigateToHelp = () => navigate("/help");
    const navigateToDocuments = () => navigate("/documents");
    const navigateVoid = () => navigate("");
    const navigateToProfile = () => navigate("/profile");

    const logOut = () => auth.signoutRedirect();

    return (
        <div className="grid lg:auto-rows-fr grid-cols-4 gap-md lg:gap-xl relative">
            <div id="dashboardProductsBar"
                 className="hidden lg:block absolute bg-lime-blue w-7 h-full -left-10 rounded-xl">
                <div id="dashboardProductsBarDot" className="absolute rounded-full bg-lime-blue
            w-20 h-20 -left-3 transform -translate-x-1/2
            top-1/2 -translate-y-1/2 flex items-center justify-center">
                    <img src={imgGuide} alt="Centered Image" className="w-12 h-12"/>
                </div>
            </div>
            <DashboardTile type={TileType.Documents}
                           title="Documents"
                           action={navigateToDocuments}
                           imageUrl={imgDocuments}
                           order={1}
                           orderSm={1}>
                <span className="font-medium">T’s & C’s, policies, key documents</span>
            </DashboardTile>
            <DashboardTile type={TileType.Profile}
                           title="Profile"
                           action={navigateToProfile}
                           imageUrl={imgMember}
                           order={3}
                           orderSm={2}>
                <span className="font-medium">Check your details, change your password</span>
            </DashboardTile>
            <DashboardTile type={TileType.Guidance}
                           title="Guidance"
                           imageUrl={imgArrows}
                           action={navigateToHelp}
                           order={2}
                           orderSm={3}>
                <span className="font-medium">Get help, get answers, get in touch</span>
            </DashboardTile>
            <DashboardTile type={TileType.Feedback}
                           title="Feedback"
                           action={navigateVoid}
                           imageUrl={imgTrustpilot}
                           isHalfSizeOnDesktop={true}
                           isHiddenMobile={true}
                           order={4}
                           orderSm={4}>
                <span></span>
            </DashboardTile>
            <DashboardTile type={TileType.LogOut}
                           title="Log Out"
                           action={logOut}
                           imageUrl={imgExit}
                           isHalfSizeOnDesktop={true}
                           order={5}
                           orderSm={5}>
                <span></span>
            </DashboardTile>
        </div>
    );
};

export default DashboardOtherTiles;