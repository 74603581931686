import React from 'react';

const HealthTrackerNoData: React.FC = () => {
    return (
        <div className="text-center p-4">
            <h2>No Health Data Available</h2>
            <p>We're unable to retrieve your health score at the moment. Please try again later.</p>
        </div>
    );
};

export default HealthTrackerNoData;
