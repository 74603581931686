import React from 'react';
import {useNavigate} from "react-router-dom";

const HealthTrackerNoFirstScan: React.FC = () => {
    const navigate = useNavigate();
    const navigateToHome = () => navigate("/HealthTracker", {state: {showHealthTrackerHome: true}});
    
    return (
        <div className="text-center p-5 flex flex-col justify-center items-center">
            <h2 className="mb-5">You haven't completed your first scan yet</h2>
            <div onClick={navigateToHome}
                 className="flex rounded-xl bg-lime-orange p-4 font-medium items-center space-x-4 
                    cursor-pointer justify-center hover:no-underline h-full sm:w-[14.875em] text-center ">
                    <span className="text-h1 flex flex-row align-middle">
                        <strong>Scan now</strong>
                    </span>
            </div>
        </div>
    );
};

export default HealthTrackerNoFirstScan;
