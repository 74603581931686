import React, { useState } from 'react';
import { useAuth } from "react-oidc-context";
import { getClaimValue } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import {composeUrl, limeApiGet} from "../../utils/api";
import {formatViewDateFullYear} from "../../utils/dateFormat";

interface ProfileDetails {
  firstName: string,
  lastName: string,
  email: string,
  dateOfBirth: Date,
  startDate: Date
}

function Profile() {
  const auth = useAuth()
  const [organisationName] = useState(getClaimValue(auth.user, LimeClaimTypes.OrganisationName));
  const [profileDetails, setProfileDetails] = useState<ProfileDetails | null>(null);
  const [changePasswordUrl, setChangePasswordUrl] = useState("#");

  React.useEffect(() => {
    const fetchData = async () => {
      const configUrl = composeUrl('configuration');
      const response = await fetch(configUrl);
      const config = await response.json();
      const url = `${config.auth}/Account/ChangePassword?returnUrl=${encodeURIComponent(window.location.href)}`;
      setChangePasswordUrl(url);
    }

    const fetchProfileDetails = async () => {
      const response = await limeApiGet<ProfileDetails>('profile', auth);
      setProfileDetails(response);
    }

    void fetchData();
    void fetchProfileDetails();
  }, []);

  return (
    <div>
      <h2 className="sm:text-h1">Profile</h2>
      <hr className="lg:mr-40 my-sm sm:my-md bg-mid-grey"></hr>
      <h4 className="sm:text-h3 sm:mb-sm">Information</h4>
      <p className="text-p2 sm:text-p1"><span className="font-bold">Name: </span>{auth.user?.profile.name}</p>
      <p className="text-p2 sm:text-p1"><span className="font-bold">Email: </span>{auth.user?.profile.email}</p>
      <p className="text-p2 sm:text-p1"><span className="font-bold">Organisation: </span>{organisationName}</p>
      <p className="text-p2 sm:text-p1"><span className="font-bold">Start Date: </span>{formatViewDateFullYear(profileDetails?.startDate)}</p>
      <hr className="lg:mr-40 my-sm sm:my-md bg-mid-grey"></hr>
      <h4 className="sm:text-h3 sm:mb-sm">Change Password</h4>
      <p className="text-p2 sm:text-p1 mb-md">Change your password below</p>
      <a className="w-fit block px-lg py-sm font-bold text-primary-text visited:text-primary-text active:text-primary-text bg-button-background-blue rounded-md font-sans-co" href={changePasswordUrl}>Change Password</a>
      <hr className="lg:mr-40 my-sm sm:my-md bg-mid-grey"></hr>
    </div>
    );
}

export default Profile;