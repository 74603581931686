import React, {useState, useEffect} from "react";
import {useAuth} from "react-oidc-context";
import {useNavigate} from 'react-router-dom';
import {composeUrl} from "../../utils/api";
import {formatViewDateFullYear} from "../../utils/dateFormat";
import CashClaimDetails from "../../models/CancerNavigatorCashClaimDetails";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";
import {CancerNavigatorCashClaimStatusEnums} from '../../models/CancerNavigatorCashClaimStatusEnums';

function CashClaimService() {
    const navigate = useNavigate();
    const auth = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [cashClaimDetails, setCashClaimDetails] = useState<CashClaimDetails | null>(null);

    const navigateToCashClaim = () => {
        navigate('/cancer/cashclaim');
    };

    const getStartCashClaimButton = () => {
        return (
            <>
                <div>
                    <button
                        className="text-p1 px-md lg:px-xl py-sm pt-xs w-fit font-sans-co"
                        onClick={navigateToCashClaim}>
                        Get Started
                    </button>
                </div>
            </>
        );
    }

    const getCashClaimMessage = () => {
        // No claim
        if (cashClaimDetails === null) {
            return (
                <>
                    <div className="mb-lg">
                        <p>
                            Navigate your cancer journey with our financial support, available to eligible users.
                            Designed to ease financial pressures, our assistance ensures you can concentrate
                            more on your recovery and well-being.
                        </p>
                    </div>
                    {getStartCashClaimButton()}
                </>
            );
        }
        // Previous claim, but can submit a new claim
        else if(cashClaimDetails.statusId === CancerNavigatorCashClaimStatusEnums.Denied){
            return (
                <>
                    <div className="mb-lg">
                        <p>
                            Unfortunately your last claim was <strong>denied</strong>. The reason was communicated to you via email.
                        </p>
                        <p>
                            If you have new supporting evidence then you may submit another claim.
                        </p>
                        <br/>
                        {getStartCashClaimButton()}
                    </div>
                </>
            );
        }
        // Ongoing claim, but cannot submit a new claim
        else if(
            cashClaimDetails.statusId === CancerNavigatorCashClaimStatusEnums.Raised ||
            cashClaimDetails.statusId === CancerNavigatorCashClaimStatusEnums.Approved) {
            const diagnosisDate = new Date(cashClaimDetails.diagnosisDate);
            const diagnosisDateString = formatViewDateFullYear(diagnosisDate);
            return (
                <>
                    <div className="mb-lg">
                        <p>
                            Claim <b>{cashClaimDetails.reference}</b> was raised on <b>{diagnosisDateString}</b> and is
                            currently being <b>{cashClaimDetails.statusName}</b>.
                        </p>
                        <br/>
                        <p>
                            You’ll receive a notification when the status of the claim is updated.
                        </p>
                    </div>
                </>
            );
        }
        // Paid
        else{
            return (
                <>
                    <div className="mb-lg">
                        <p>
                            Your previous claim <b>{cashClaimDetails.reference}</b> was <strong>paid</strong>.
                        </p>
                        <br/>
                        <p>
                            Thank you for letting us help you during this difficult time.
                        </p>
                    </div>
                </>
            );
        }
    }

    const getLatestCashClaim = (claims: CashClaimDetails[]) => {
        if (!Array.isArray(claims) || claims.length === 0) {
            return null;
        }

        const sortedClaims = claims.sort((a, b) => b.reference.localeCompare(a.reference));
        return sortedClaims[0];
    };

    const fetchExistingCashClaim = () => {
        setLoading(true);
        fetch(composeUrl('cancerNavigator/cashClaims'), {
            headers: [['Authorization', `Bearer ${auth.user?.access_token}`]]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const latestClaim = getLatestCashClaim(data);
                setCashClaimDetails(latestClaim);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    React.useEffect(() => {
        fetchExistingCashClaim();
    }, []);

    return (
        <>
            <LoadingSpinner loading={loading}>
                {getCashClaimMessage()}
            </LoadingSpinner>
        </>
    );
}

export default CashClaimService;