import React, { useState } from 'react';
import {Tab, TabContent, Tabs} from "../Shared/Tabs";
import PeerServices from "./PeerServices";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";
import EligibilityJourney from "./EligibilityJourney";
import MyServices from "./MyServices";
import {useLocation} from "react-router-dom";
import { composeUrl } from "../../utils/api";
import { useAuth } from "react-oidc-context";
import Eligibility  from "../../models/CancerNavigatorEligibility";

function Home() {
    const { state } = useLocation();
    const auth = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [eligibilityAnswers, setEligibilityAnswers] = useState<Eligibility | null>(null);
    const restartEligibility = state ? state["restartEligibility"] ?? false : false;

    const handleRestartEligibility = () => {
        setEligibilityAnswers(null);
    };

    const handleEligibilityComplete = (eligibilityData: Eligibility) => {
        setEligibilityAnswers(eligibilityData);
        fetchEligibilityAnswers();
    };

    const fetchEligibilityAnswers = () => {
        setLoading(true);
        fetch(composeUrl('cancernavigator/eligibility/answers'), {
            headers: [['Authorization', `Bearer ${auth.user?.access_token}`]]
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const answersObject: { [key: string]: boolean } = {};
                for (const key in data.answers) {
                    answersObject[key] = data.answers[key];
                }

                const eligibility = new Eligibility(data.createdOn, data.diagnosisDate, answersObject, data.eligibleServices);
                setEligibilityAnswers(eligibility);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    React.useEffect(() => {
        fetchEligibilityAnswers();
    }, []);

    return (
        <div className="flex flex-col gap-md max-w-[50rem]">
            <h3>Cancer Navigator</h3>
            <h4 className="font-normal">Who needs cancer support?</h4>
            <Tabs>
                <Tab tabKey="1" name="Me"/>
                <Tab tabKey="2" name="Someone else"/>
                <TabContent associatedTabKey="1">
                    <LoadingSpinner loading={loading}>
                        {eligibilityAnswers && !restartEligibility
                            ? <MyServices eligibility={eligibilityAnswers} onRestartEligibility={handleRestartEligibility}/>
                            : <EligibilityJourney onEligibilityComplete={handleEligibilityComplete}/>
                        }
                    </LoadingSpinner>
                </TabContent>
                <TabContent associatedTabKey="2">
                    <PeerServices/>
                </TabContent>
            </Tabs>
        </div>
    );
}

export default Home;