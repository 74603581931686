import React, { FunctionComponent, useState } from "react";
import Checked from "../../../images/checkbox-checked.svg";
import Unchecked from "../../../images/checkbox-unchecked.svg";

interface RadioOption {
    name: string,
    value: string,
    label: string
}

const RadioGroup: FunctionComponent<{
    options: RadioOption[];
    onChange: (selectedValue: string) => void;
}> = ({ options, onChange }) => {
  const [selectedValue, setSelectedValue] = useState<string>();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
      setSelectedValue(newValue);
      onChange(newValue);
  };

  return (
    <div className="flex flex-row gap-xl px-sm">
        {options.map((option) => (
            <label key={option.value} className="text-p1 flex items-center mb-xs">
                <input
                    type="radio"
                    id={option.name + option.value}
                    name={option.name}
                    value={option.value}
                    checked={selectedValue === option.value}
                    onChange={handleRadioChange}
                    className="hidden"
                />
                <img
                    key={option.value}
                    src={
                        selectedValue === option.value
                        ? Checked
                        : Unchecked
                    }
                    alt={option.label}
                    className="mr-sm"
                />
                {option.label}
            </label>
        ))}
    </div>
  );
};

export default RadioGroup;