import React, { useState } from "react";
import { composeUrl } from "../../utils/api";
import { useAuth } from "react-oidc-context";
import { getClaimValue } from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import { formatViewDate } from "../../utils/dateFormat";

interface Props {
  isOpen: boolean,
  setIsOpen: (value: boolean) => void,

  startDate: Date
}

function getUnlockDate(startDate: Date) {
  const date = new Date(startDate);
  date.setFullYear(date.getFullYear() + 1);
  return date;
}

function OptOutModal({ isOpen, setIsOpen, startDate }: Props) {
  const auth = useAuth();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [organisationName] = useState(getClaimValue(auth.user, LimeClaimTypes.OrganisationName));
  const [unlockDate] = useState(getUnlockDate(startDate));
  const [error, setError] = useState("");

  const submit = async () => {
    if(!isConfirmed) {
      setError("Confirmation required");
      return;
    }

    const response = await fetch(composeUrl(`optOut`),
    {
      headers: [[ 'Authorization',  `Bearer ${auth.user?.access_token}` ], [ 'Content-Type', 'application/json' ]],
      method: 'POST'
    });
    if (response.ok) {
      await auth.signoutRedirect();
    } else {
      setError("Sorry an error has occurred");
    }
  }

  const close = () => {
    setIsConfirmed(false);
    setError("")
    setIsOpen(false);
  };

  const confirm = () => {
    setIsConfirmed(!isConfirmed);
  };

  return(
    <>
      <div className={`fixed top-0 left-0 z-40 h-full w-full bg-dark-grey/[.5] px-md${isOpen ? '' :  ' hidden'}`}>
      </div>
      <div className={`absolute top-0 left-0 z-50 w-full p-xl tall:p-2xl ${isOpen ? '' :  ' hidden'}`}>
        <div className={`bg-pure-white p-xl tall:p-2xl max-w-[41rem] mx-auto mt-2xl rounded-md text-center`}>
          <p className="mb-md text-p1 font-bold sm:text-h4">Are you sure you want to opt-out?</p>
          <p className="mb-md text-p2 sm:text-p1 font-medium">
            If you opt-out of the {organisationName} OnCall health and wellbeing service(s) you will lose access to them for the next <b>12 months</b>.
          </p>
          <p className="mb-md text-p2 sm:text-p1 font-medium">
            Your employer will continue to invite you to join any new OnCall health and wellbeing service(s) and offer you an annual review,
            by automatically re-joining you when a new service is launched or on the <b>{formatViewDate(unlockDate)}*</b>
          </p>
          <p className="mb-md text-p2 sm:text-p1 font-medium">
            If you’re not sure about how to use the service(s) or have any questions or concerns,
            please check out our <a href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/LimeOnCallOpt-out+FAQs.pdf" target="_blank">FAQs</a> or
            contact us on <a href="mailto:contact@limeoncall.co.uk">contact@limehealth.uk</a>.
          </p>
          <div className="mb-md font-medium">
            <input type="checkbox" onChange={confirm} checked={isConfirmed} />
            <span className="ml-4 text-p2 sm:text-p1">I confirm I want to opt-out of the {organisationName} OnCall health and wellbeing service(s).</span>
          </div>
          <p className={`mb-md font-medium text-error-red${error ? '' : ' hidden'}`}>
            {error}
          </p>
          <div className="flex mb-md  gap-sm sm:gap-lg flex-row items-center justify-center">
            <button onClick={submit} className="bg-pure-white border-solid border border-light-grey font-bold text-primary-text rounded-md text-p2 font-sans-co w-fit px-lg py-3">
              Opt-Out
            </button>
            <button onClick={close} className="bg-button-background-blue border-background-blue font-bold text-primary-text rounded-md text-p2 font-sans-co w-fit px-lg py-3">
              Go Back
            </button>
          </div>
          <p className="mb-md text-p3 sm:text-p2 font-medium">
            * You will be given the option to opt-out again if you do not wish to receive the health and wellbeing services.
          </p>
        </div>
      </div>
    </>
  );
}

export default OptOutModal;