import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";
import useProducts from "../../utils/useProducts";
import {ProductIds} from '../../models/ProductEnums';
import DashboardTileProduct, {TileProductName} from "./DashboardTileProduct";
import {getFeatureFlag} from "../../utils/featureFlag";
import {AuthContextProps} from "react-oidc-context"; // Import the type if available
import imgKeys from "../../images/dashboard/keyspng.png";

interface ProductsProps {
    auth: AuthContextProps;
}

function Products({ auth }: ProductsProps) {
    const navigate = useNavigate();
    const { products, loading: productsLoading } = useProducts();

    const [fastAnswersUsed, setFastAnswersUsed] = useState<boolean>(false);

    const hasHealthEssentials = products.some(p => p.productUuid === ProductIds.HealthEssentials);
    const hasCancerNavigator = products.some(p => p.productUuid === ProductIds.CancerNavigator);
    const hasHealthTracker = products.some(product => product.productUuid === ProductIds.HealthTracker);

    const navigateToDocuments = () => navigate("/documents");
    const navigateToHealthEssentials = () => navigate("/healthEssentials");
    const navigateToCancerNavigator = () => navigate("/cancer");
    const navigateToHealthTracker = () => navigate("/healthTracker");

    const tiles = [];
    if (hasHealthTracker) {
        tiles.push(
            <DashboardTileProduct
                key="HealthTracker"
                product={TileProductName.HealthTracker}
                title="Health Tracker"
                actionText={"Next"}
                action={navigateToHealthTracker}
                isFullWidth={false}
            />
        );
    }
    if (hasHealthEssentials) {
        tiles.push(
            <DashboardTileProduct
                key="HealthEssentials"
                product={TileProductName.HealthEssentials}
                title="Health Essentials"
                actionText={"Next"}
                action={navigateToHealthEssentials}
                isFullWidth={false}
            />
        );
    }
    if (hasCancerNavigator) {
        tiles.push(
            <DashboardTileProduct
                key="CancerNavigator"
                product={TileProductName.CancerNavigator}
                title="Cancer Navigator"
                actionText="Next"
                action={navigateToCancerNavigator}
                isFullWidth={false}
            />
        );
    }

    return (
        <LoadingSpinner loading={productsLoading}>
            <div id="dashboardProducts" className="relative">
                <div id="dashboardProductsBar" className="hidden lg:block absolute bg-lime-bg w-7 h-full -left-10 rounded-xl">
                    <div id="dashboardProductsBarDot"
                         className="absolute rounded-full bg-lime-bg
                         w-20 h-20 -left-3 transform -translate-x-1/2
                         top-1/2 -translate-y-1/2 flex items-center justify-center">
                        <img src={imgKeys} alt="Centered Image" className="w-12 h-12" />
                    </div>
                </div>
                <div className="grid mb-xl lg:auto-rows-fr grid-cols-1 lg:grid-cols-3 2xl:grid-cols-6 gap-md lg:gap-xl">
                    {tiles.map((tile, index) => {
                        return (
                            <div key={index} className={`lg:col-span-3 h-full`}>
                                {React.cloneElement(tile)}
                            </div>
                        );
                    })}
                </div>
            </div>
        </LoadingSpinner>
    );

}

export default Products;