import React from 'react';

export enum TileType {
    Documents,
    Profile,
    Guidance,
    Feedback,
    LogOut,
}

interface Props {
    type: TileType;
    title: string;
    actionText?: string;
    action?: Function;
    imageUrl?: string;
    isHalfSizeOnDesktop?: boolean;
    isHiddenMobile?: boolean;
    children: React.ReactNode;
    order: number;
    orderSm: number;
}

function DashboardTile(props: Props) {
    function bgColourStyle() {
        switch (props.type) {
            case TileType.Documents:
                return "bg-lime-turk";
            case TileType.Profile:
                return "bg-lime-tan";
            case TileType.Guidance:
                return "bg-lime-tan";
            case TileType.Feedback:
                return "bg-lime-fg";
            case TileType.LogOut:
                return "bg-lime-slate";
            default:
                return "bg-yellow";
        }
    }

    function imageStyle() {
        return props.imageUrl ? "sm:basis-2/3" : "";
    }

    function sizeStyle() {
        return props.isHalfSizeOnDesktop ? "sm:col-span-1 sm:flex-col" : "sm:col-span-2 sm:flex-row";
    }

    function hiddenMobile(){
        return props.isHiddenMobile ? "hidden sm:flex" : "flex";
    }

    const orderClass = `order-${props.order}`;
    const orderSmClass = `sm:order-${props.orderSm}`;

    return (
        <div onClick={() => props.action?.()} className={`
        ${bgColourStyle()} ${hiddenMobile()}
        cursor-pointer shadow-tile rounded-lg p-md xl:p-lg
        justify-between items-stretch  
        col-span-2
        ${sizeStyle()}
        ${orderClass}
        ${orderSmClass}
        `}>
            <div className={`tileInfo flex flex-col justify-between gap-sm xl:gap-xs w-full basis-3/3 ${imageStyle()}`}>
                <div className="w-full text-center sm:text-left">
                    <h1 className={`font-sans-co font-bold mb-sm text-h5 sm:text-h4 lg:text-h3 text-lime-blue`}>
                        {props.title}
                    </h1>
                    <div className="text-p2 sm:text-p0">
                        {props.children}
                    </div>
                </div>
                {props.action && props.actionText?.length !== undefined && props.actionText?.length > 0 && (
                    <button className="text-p1 px-md lg:px-xl py-sm pt-xs w-fit font-sans-co" >
                        {props.actionText}
                    </button>
                )}
            </div>
            {props.imageUrl &&
                <div className="hidden tileImg basis-1/3 sm:flex flex-row-reverse items-center">
                    <img src={props.imageUrl} alt={props.title} className="h-full max-h-[4em] xl:max-h-[5.5rem]"/>
                </div>
            }
        </div>
    );
}

export default DashboardTile;