import React, { useEffect, useState } from 'react';
import { HealthScoreLink, Nudge, Measurement, PageData } from './HealthTrackerConstants';
import { useAuth } from 'react-oidc-context';
import { composeUrl } from '../../utils/api';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import HealthTrackerMainResults from './HealthTrackerMainResults';
import HealthTrackerNoData from './HealthTrackerNoData';
import HealthTrackerResultsIncompleteScan from './HealthTrackerResultsIncompleteScan';

interface PreviousScansProps {
    scanResults: HealthScoreLink[] | null;
    nextAllowedScanDate: Date | null;
}

const getLatestCompletedScan = (scans: HealthScoreLink[] | null): HealthScoreLink | null => {
    if (!scans || scans.length === 0) {
        return null;
    }
    const completedScans = scans.filter(scan => scan.status.toLowerCase() === 'completed');
    if (completedScans.length === 0) {
        return null;
    }
    completedScans.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    return completedScans[0];
};

const PreviousScanResults: React.FC<PreviousScansProps> = ({ scanResults, nextAllowedScanDate }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [pageData, setPageData] = useState<PageData | null>(null);
    const auth = useAuth();

    // Use effect to fetch the latest completed scan data
    useEffect(() => {
        const latestCompletedScan = getLatestCompletedScan(scanResults);
        if (latestCompletedScan) {
            const guid = latestCompletedScan.limeId;
            const fetchSingleScan = async () => {
                try {
                    const response = await fetch(composeUrl(`v1/healthTracker/${guid}`), {
                        headers: {
                            Authorization: `Bearer ${auth.user?.access_token}`,
                            'Content-Type': 'application/json',
                        },
                        method: 'GET',
                    });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setPageData(data || null);
                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                } finally {
                    setLoading(false);
                }
            };
            fetchSingleScan();
        } else {
            setLoading(false);
        }
    }, [scanResults, auth.user?.access_token]);

    const healthScore = pageData?.healthScore;
    const measurements: Measurement[] = (pageData?.measurements as Measurement[]) || [];

    let content;

    if (loading) {
        content = null;
    } else {
        if (pageData === null) {
            content = <HealthTrackerNoData />;
        } else if (!healthScore || measurements.length === 0) {
            content = <HealthTrackerResultsIncompleteScan />;
        } else {
            content = (
                <HealthTrackerMainResults
                    healthScoreValue={healthScore?.score}
                    benchmark={healthScore?.benchmark || ''}
                    calculationDate={pageData?.scanDate || ''}
                    nudges={(healthScore?.healthScoreNudges as Nudge[]) || []}
                    measurements={measurements}
                />
            );
        }
    }

    return (
        <LoadingSpinner loading={loading}>
            {content}
        </LoadingSpinner>
    );
};

export default PreviousScanResults;
