import React, { useState } from "react";
import { useAuth } from "react-oidc-context";
import {ScrollRestoration} from "react-router-dom";
import WelcomeAvatar from "../../images/welcome-avatar.svg";

function WelcomeIntro({ onGetStarted } : any) {
  const auth = useAuth()
  const [userName] = useState(auth.user?.profile.nickname);
  return (
    <main className="text-center bg-pure-white px-md sm:px-auto py-md
        col-start-1 col-span-4 sm:col-span-12 row-start-2 row-span-1">
      <div className="sm:mx-xl md:mx-4xl sm:grid sm:grid-cols-2">
        <div className="sm:col-start-1">
          <h2 className="my-xl sm:text-h1 text-deep-blue">
            Hi {userName},<br/>
            <div className="">
              <span className="inline">Welcome to Lime On</span>
              <span className="inline text-lime-green">Call</span>
            </div>
          </h2>
          <h3 className="mb-2xl sm:text-h2 text-deep-blue">
            Simple, confidential<br/>
            healthcare.<br/>
            Zero faff.
          </h3>
          <p className="mb-md sm:mb-2xl font-sans-co">
            <input id="next" className="!bg-deep-blue" type="button" value="Get started" onClick={onGetStarted}/>
          </p>
        </div>
        <div className="flex justify-center sm:col-start-2">
          <img src={WelcomeAvatar} alt="Welcome Avatar" className="my-2xl sm:my-md sm:float-right sm:mt-4xl sm:h-64"/>
        </div>        
      </div>
      <ScrollRestoration />
    </main>
  );
}

export default WelcomeIntro;