import React, {useState, useEffect} from 'react';
import {Nudge, PageData, Measurement, HealthScoreLink} from './HealthTrackerConstants';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import {composeUrl} from '../../utils/api';
import {useAuth} from 'react-oidc-context';
import HealthTrackerNoData from './HealthTrackerNoData';
import HealthTrackerResultsIncompleteScan from './HealthTrackerResultsIncompleteScan';
import PreviousScanResults from './PreviousScanResults';
import HealthTrackerMainResults from './HealthTrackerMainResults';
import HealthTrackerNoFirstScan from './HealthTrackerNoFirstScan';

function HealthTrackerResults() {
    const [loading, setLoading] = useState<boolean>(true);
    const [pageData, setPageData] = useState<PageData | null>(null);
    const [scanResults, setScanResults] = useState<HealthScoreLink[] | null>(null);
    const [nextAllowedScanDate, setNextAllowedScanDate] = useState<Date | null>(null);
    const auth = useAuth();

    function checkIfRedirectFromScan(guid: string): boolean {
        return !!(guid && guid !== "results");
    }

    function hasCompletedScan(results: HealthScoreLink[] | null): boolean {
        return (results ?? []).some(result => result.status.toLowerCase() === 'completed')
    }

    const pathname = window.location.pathname;
    const pathParts = pathname.split('/');
    const guid = pathParts[pathParts.length - 1];
    const isRedirectFromScan = checkIfRedirectFromScan(guid);

    const fetchSingleScan = async () => {
        try {
            const response = await fetch(composeUrl(`v1/healthTracker/${guid}`), {
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`,
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPageData(data || null);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchPreviousScans = async () => {
        try {
            const response = await fetch(composeUrl('v1/healthTracker'), {
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`,
                    'Content-Type': 'application/json',
                },
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setScanResults(data?.healthScoreLinks || []);
            setNextAllowedScanDate(data?.nextAllowedScanDate || null);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isRedirectFromScan) {
            fetchSingleScan();
        } else {
            fetchPreviousScans();
        }
    }, [auth.user?.access_token, guid, isRedirectFromScan]);

    const healthScore = pageData?.healthScore;
    const measurements: Measurement[] = (pageData?.measurements as Measurement[]) || [];

    let content;

    if (loading) {
        content = null;
    } else {
        if (isRedirectFromScan) {
            if (pageData === null && scanResults === null) {
                content = <HealthTrackerNoData />;
            } else if (!healthScore || measurements.length === 0) {
                content = <HealthTrackerResultsIncompleteScan />;
            } else {
                content = (
                    <HealthTrackerMainResults
                        healthScoreValue={healthScore!.score}
                        benchmark={healthScore!.benchmark || ''}
                        calculationDate={pageData!.scanDate}
                        nudges={(healthScore!.healthScoreNudges as Nudge[]) || []}
                        measurements={measurements}
                    />
                );
            }
        }
        // Check for completed scans
        else if (hasCompletedScan(scanResults)) {
            content = (
                <PreviousScanResults
                    scanResults={scanResults}
                    nextAllowedScanDate={nextAllowedScanDate}
                />
            );
        }
        // Has never submitted a scan
        else {
            content = <HealthTrackerNoFirstScan />;
        }
    }

    return <LoadingSpinner loading={loading}>{content}</LoadingSpinner>;
}

export default HealthTrackerResults;
