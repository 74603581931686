import React, { MouseEvent } from "react";
import ButtonSpinner, { SpinnerTheme } from "./ButtonSpinner";

export enum ButtonStyle {
    Primary,
    Secondary
}

interface Props {
    loading: boolean;
    submit?: boolean;
    buttonStyle?: ButtonStyle;
    action?: Function;
    disabled?: boolean;
    children: React.ReactNode;
}

function StateButton(props: Props) {
    let isSecondary = props.buttonStyle && props.buttonStyle == ButtonStyle.Secondary;
    let buttonStyle = isSecondary ? "secondary" : "";

    function handleClick(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (props.action) {
            props.action();
        }
    }

    return (
        <button className={buttonStyle} disabled={props.loading || (props.disabled ?? false)}
                onClick={props.submit ? undefined : handleClick}>
            <ButtonSpinner enabled={props.loading} theme={isSecondary ? SpinnerTheme.Dark : SpinnerTheme.Light}/>
            {props.children}
        </button>
    );
}

export default StateButton;