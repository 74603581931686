import React from "react";
import SpinnerLight from "../../../images/spinner-light.svg";
import SpinnerDark from "../../../images/spinner-dark.svg";

export enum SpinnerTheme {
  Dark,
  Light
}

interface Props {
  enabled: boolean;
  theme: SpinnerTheme;
}

function ButtonSpinner(props: Props) {
  function src() {
    return props.theme == SpinnerTheme.Dark ? SpinnerDark : SpinnerLight;
  }
  function style() {
    let spinnerStyle = "h-md w-md inline-block mr-sm animate-spin";
    return props.enabled ? spinnerStyle : spinnerStyle + " hidden";
  }

  return (
      <img src={src()} alt="spinner" className={style()} />
  );
}

export default ButtonSpinner;