export const memberDocs = [
    {
        name: "Lime Member's Privacy Policy",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/MemberPrivacyPolicy.pdf",
    },
    { 
        name: "Lime Terms and Conditions", 
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/MemberTermsAndConditions.pdf"
    },
    {
        name: "Cookies",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/OnCallCookiePolicy.pdf"
    },
    {
        name: "Lime General FAQs",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Lime+Health+FAQs.pdf"
    },
    {
        name: "Company About",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/AboutLimeMember+2.0.pdf"
    },
    {
        name: "Company FAQs",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Lime+Health+FAQs.pdf"
    },
    {
        name: "Company Accessibility Statement",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Company/Documents/OnCallAccessibilityStatement.pdf"
    },
    {
        name: "Security Policy",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/OnCallSecurityStatement.pdf"
    }
];
export const healthTrackerDocs  = [ 
    { 
        name: "General FAQs", 
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Tracker+FAQ+31-10-2024.pdf"
    },
    {
        name: "Data Privacy FAQs",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/HT+Data+Privacy+FAQ.pdf"
    },
    {
        name: "Face Scan FAQs",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Preparing+for+your+face+scan.pdf"
    },
    {
        name: "Service Summary",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Tracker+service+summary+31-10-2024.pdf"
    },
    {
        name: "Alula Privacy Policy",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/AlulaHealthPrivacy+Policy_v1.4.pdf"
    },
    {
        name: "Alula Data Sharing consent",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/AlulaHealthConsent_v1.4.pdf"
    }
];
export const fastAnswersDocs = [
    {
        name: "Health Essentials Service Summary",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Essentials+Service+Summary.pdf"
    },
    {
        name: "Health Essentials FAQ",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Essentials+FAQs.pdf"
    },
]
export const cancerNavigatorDocs = [
    {
        name: "Cancer Navigator Service Summary",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/MemberCancerNavigatorServiceSummary.pdf"
    },
    {
        name: "Cancer Navigator Insurance Product Information Document",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/MemberCancerNavigatorIPID.pdf"
    },
    {
        name: "Cancer Navigator FAQs",
        href: "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Cancer+Navigator+FAQs.pdf"
    },
]