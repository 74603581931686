import React, {useState} from 'react';
import {useLocation, useLoaderData} from "react-router-dom";
import ProductInfo from "../../components/CancerNavigator/ProductInfo";
import Home from "../../components/CancerNavigator/Home";
import {useAuth} from "react-oidc-context";

function Cancer() {
    const auth = useAuth();
    const { state } = useLocation();

    const showCancerNavigatorHome = state ? state["showCancerNavigatorHome"] ?? false : false;

    return (
        <div>
            { showCancerNavigatorHome ? <Home /> : <ProductInfo /> }
        </div>
    );
}

export default Cancer;